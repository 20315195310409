import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Hero() {
    const { t } = useTranslation();
    return (
        <div>
            <section id="hero" className="d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1" data-aos="fade-up" data-aos-delay={200}>
                        <h1>{t('hero.title')}</h1>
                        <h2>{t('hero.description1')}</h2>
                        <h2>{t('hero.description2')}</h2>

                            <div className="d-flex justify-content-center justify-content-lg-start">
                                <a href="#contact" className="btn-get-started scrollto">{t('hero.cta')}</a>
                                {/*                                 <a href="https://www.youtube.com/watch?v=jDDaplaOz7Q" className="glightbox btn-watch-video"><i className="bi bi-play-circle" /><span>Watch Video</span></a>
*/}
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay={200}>
                            <img src="assets/img/logos/logo7.png" className="img-fluid animated" alt />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    )
}
