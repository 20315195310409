import React from 'react';

// Assuming priceKeyToText is defined in the same file or imported
const priceKeyToText = {
  mini: "Mini Size",
  small: "Small Size",
  mid: "Medium Size",
  large: "Large Size",
  extraLarge: "Extra Large",
  telarkenr: "Dish",
  oneSlice: "1 Slice",
  twoSlice: "2 Slices",
  threeSlice: "3 Slices",
  roll: "Roll",
  twoPI: "2 PI"
  // Add more mappings as needed
};

const ItemComponent = ({ item }) => {
  // Dynamically generating price tags with readable text from the item's prices object
  const priceTags = Object.entries(item.prices).map(([key, price], index) => {
    const readableKey = priceKeyToText[key] || key; // Fallback to key if no mapping found
    return <span key={index}>{`${readableKey}: ${price}`}</span>;
  });

  return (
    <div className="menu-item">
      <div className="menu-item-image">
        <img src={process.env.PUBLIC_URL + item.imageUrl} alt={item.name} />
      </div>
      <div className="menu-item-info">
        <h3>{item.name}</h3>
        <p className="menu-item-description">{item.description}</p>
        <div className="menu-item-dietTag">{item.dietTag}</div>
        <div className="menu-item-prices">
          {priceTags}
        </div>
      </div>
    </div>
  );
};

export default ItemComponent;
