import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// Assume this import if your languages are defined separately
// import { resources } from './path/to/your/i18nConfig';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  // Assuming resources is accessible, either directly imported or from i18n
  const languages = i18n.options.resources ? Object.keys(i18n.options.resources) : [];

  // A simple mapping for showing language names in their native form or English
  const languageNames = {
    en: 'English',
    no: 'Norsk',
    pl: "Polish",
    de: 'Deutsch',
    es: 'Español',
    it: 'Italiano',
    fr: 'Français',
    ja: '日本語',
    zh: '中文',
    so: 'Soomaali',
    // Extend this list with the language codes and names as needed
  };

  useEffect(() => {
    // Update the state whenever the language changes
    const langName = languageNames[i18n.language] || 'English'; // Default to English if no match found
    setCurrentLang(langName);
  }, [i18n.language, languageNames]);

  const changeLanguage = (language) => {
    localStorage.setItem('language', language); // Save the language preference
    i18n.changeLanguage(language);
  };

  return (
    <div className="language-switcher">
      <span>{currentLang} &#x25BC;</span>
      <div className="dropdown-content">
        {languages.map((code) => (
          <a key={code} href="#" onClick={(e) => { e.preventDefault(); changeLanguage(code); }}>
            {languageNames[code] || 'Unknown'} {/* Fallback to 'Unknown' if no name is found */}
          </a>
        ))}
      </div>
    </div>
  );
};

export default LanguageSwitcher;
