import React, { useState, useEffect } from 'react';
import LanguageSwitcher from './LanguageSwitcher'; // Adjust the path based on your project structure
import { useTranslation } from 'react-i18next';

const NavWrap = () => {
    const [mobileIsActive, setMobileIsActive] = useState(false);
    const { t } = useTranslation();

    const toggleClicked = () => setMobileIsActive(!mobileIsActive);

    const navClicked = () => {
        if (mobileIsActive) toggleClicked();
    };

    const select = (el, all = false) => {
        el = el.trim();
        if (all) {
            return [...document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    const infiniteScroll = () => {
        let navbarlinks = select('#navbar .scrollto', true);
        let position = window.scrollY + 200;
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return;
            let section = select(navbarlink.hash);
            if (!section) return;
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active');
            } else {
                navbarlink.classList.remove('active');
            }
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', infiniteScroll);
        return () => window.removeEventListener('scroll', infiniteScroll); // Cleanup on unmount
    }, []); // Empty array means this effect runs once on mount and cleanup on unmount

    return (
        <div>
            <nav id="navbar" className={mobileIsActive ? "navbar navbar-mobile" : "navbar "}>
                <ul>
                    <li onClick={navClicked}><a className="nav-link scrollto active" href="#hero">{t('nav_bar.home')}</a></li>
                    <li onClick={navClicked}><a className="nav-link scrollto" href="#about">{t('nav_bar.menu')}</a></li>
                    <li onClick={navClicked}><a className="nav-link scrollto" href="#services">{t('nav_bar.services')}</a></li>
                    <li onClick={navClicked}><a className="nav-link scrollto" href="#career">{t('nav_bar.career')}</a></li>
                    <li onClick={navClicked}><a className="getstarted scrollto" href="#contact">{t('nav_bar.contact_us')}</a></li>
                    <li>  <LanguageSwitcher /></li>
                </ul>
                <i className={mobileIsActive ? "bi mobile-nav-toggle bi-x" : "bi mobile-nav-toggle bi-list"}
                   onClick={toggleClicked} />
            </nav>

        </div>
    );
}

export default NavWrap;
