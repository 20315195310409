import i18n from "i18next";
import { initReactI18next } from "react-i18next";



const resources = {
  
    en: {
      translation: {
        headerTitle: 'Protein Empire By Dr. Moe',
        getStarted: {message1: 'For Catering Services, or any other inquires please reach us at the following: '},
        nav_bar: {
          home: "Home",
          menu: "Menu",
          services: "Catering Services",
          career: "Career",
          contact_us: "Contact Us",
        },
        hero: {
          title: "Indulge in Egyptian Street Delights: Where Tasty, Healthy, and Unique Flavors Roam on Wheels",
          description1: "Dive into our menu of rich vegan options, keto-friendly delights, and savory carnivorous treats, all prepared with a unique, healthy twist.",
          description2: "Enjoy the authentic taste of Egypt, meticulously crafted to cater to your dietary needs and tantalize your taste buds, now conveniently on wheels.",
          cta: "Get Started",
        },
        menuCard: {
          breads: {
            Bread: "Bread",
            baladiBruntBrod: {
              name: "Baladi Brown Bread (4 pcs)",
              description: "A hearty blend of bran, whole corn flour, and wheat flour, perfect for dipping and sandwiches, providing plenty of fiber."
            },
            baladiHvittBrod: {
              name: "Baladi White Bread (4 pcs)",
              description: "A lighter, airy option for those who prefer a more traditional base for their meals."
            },
            ketoBrod: {
              name: "Keto Bread",
              description: "Low-carb, high-fat bread that doesn’t compromise on texture or taste, perfect for those following a low-carb or ketogenic diet; it's also naturally gluten-free."
            }
          },
            "mainCourses": {
              "Name": "Main Courses",
              "fulMedames": {
                "name": "Ful Medames",
                "description": "A staple in Egyptian street food, slow-cooked fava beans spiced with tahini, spices, lemon, and olive oil."
              },
              "makaronaBashamilMincedMeat": {
                "name": "Makarona Bashamil (minced meat)",
                "description": "A comforting pasta bake with a rich, creamy béchamel sauce caramelized on top in the oven, available in two versions: minced meat, or a seafood variant with shrimp, cauliflower, broccoli, and rice flour for a pescatarian, gluten-free, and lactose-free alternative."
              },
              "makaronaBashamilShrimp": {
                "name": "Makarona Bashamil (shrimp)",
                "description": "A comforting pasta bake with a rich, creamy béchamel sauce caramelized on top in the oven, available in two versions: minced meat, or a seafood variant with shrimp, cauliflower, broccoli, and rice flour for a pescatarian, gluten-free, and lactose-free alternative."
              },
              "hawawshi": {
                "name": "Hawawshi",
                "description": "Spicy minced meat filled in Egyptian baladi bread, grilled to crispy perfection."
              },
              "fattaOriginal": {
                "name": "Fatta Original",
                "description": "A festive dish with rice, crispy fried bread, and meat layered and topped with a garlic-vinegar sauce and broth, available in carnivore and vegetarian versions where meat is replaced with eggplant."
              },
              "fattaVegan": {
                "name": "Fatta Vegan",
                "description": "A festive dish with rice, crispy fried bread, and meat layered and topped with a garlic-vinegar sauce and broth, available in carnivore and vegetarian versions where meat is replaced with eggplant."
              },
              "fatayer": {
                "name": "Fatayer",
                "description": "Flavorful Egyptian pies filled with a choice of vegetable mix or seasoned meat, baked to golden perfection."
              },
              "koshary": {
                "name": "Koshary",
                "description": "A delightful mix of lentils, rice, pasta, and a spicy tomato sauce, topped with crispy onions. A beloved vegan dish that is as nutritious as it is delicious."
              },
              "tamya": {
                "name": "Tamya",
                "description": "Unlike Falafel, which is 99% chickpeas, Tamya is made from fava beans, kneaded and centrifuged to almost a liquid form, resulting in a crispy outside and soft/fluffy inside."
              },
              "sogokAlexandria": {
                "name": "Sogok Alexandria",
                "description": "Spiced minced meat in lamb casings, offered in two regional variations, each with its unique blend of spices and cooking techniques. The Cairo version is made with caramelized onions and tiny potato cubes (Yakhni); the Alexandria version has a different blend of vegetables and spices."
              },
              "sogokKairo": {
                "name": "Sogok Kairo",
                "description": "Spiced minced meat in lamb casings, offered in two regional variations, each with its unique blend of spices and cooking techniques. The Cairo version is made with caramelized onions and tiny potato cubes (Yakhni); the Alexandria version has a different blend of vegetables and spices."
              },
              "alexandrianKebda": {
                "name": "Alexandrian Kebda",
                "description": "Tender, spiced liver cooked to perfection, a tasty treat for offal enthusiasts."
              },
              "alexandrianPulledSteakMix": {
                "name": "Alexandrian Pulled Steak Mix",
                "description": "Fine slices of beef, grilled with a mix of spices and vegetables and trimmed to small pieces, serving as a flavorful, heartwarming filling for sandwiches."
              },
              "stektOgGrilletBoltiFisk": {
                "name": "Stekt og Grillet Bolti Fisk (Nilen Tilapia)",
                "description": "Seasoned and cooked to perfection, offering a taste of the Nile's bounty."
              },
              "stektOgGrilletHummer": {
                "name": "Stekt og Grillet Hummer",
                "description": "Luxurious and flavorful, prepared with spices that complement its sweetness."
              },
              "egyptiskKofta": {
                "name": "Egyptisk Kofta",
                "description": "Grilled minced meat with rich fat content, tomato, fresh herbs, and spices, bar-shaped, butter-plated, grilled to crispy outside and juicy and rich inside."
              }
            },     
            desserts: {
              name: "Desserts",
              egyptianCactusFruit: {
                name: "Egyptian Cactus Fruit (Peeled)",
                description: "Dive into the exotic with our peeled Egyptian cactus fruit, offering a refreshing sweet treat."
              },
              zalabya: {
                name: "Zalabya (Fried Egyptian Pastry Balls)",
                description: "These light and airy sweet balls are dipped in syrup, either sprinkled with sugar or drizzled with honey, creating a perfect blend of sweetness and texture."
              },
              konafaBelKeshta: {
                name: "Konafa Bel Keshta",
                description: "Experience the ultimate dessert with our Konafa Bel Keshta, featuring a smooth, creamy filling encased in crispy, golden kunafa noodles."
              }
            },     
            appetizersAndSides: {
              name: "Appetizers and Sides",
              bazinjanPickled: {
                name: "Bazinjan Pickled",
                description: "A delicious pickled eggplant dish, seasoned with herbs and spices for a tangy flavor."
              },
              salad: {
                name: "Salad",
                description: "A fresh and crisp mix of seasonal vegetables, tossed in a light vinaigrette dressing."
              },
              tahini: {
                name: "Tahini",
                description: "A smooth and creamy paste made from ground sesame seeds, often used as a dip or sauce."
              },
              babaGanoush: {
                name: "Baba Ganoush",
                description: "A smoky eggplant dip, blended with tahini, garlic, lemon juice, and olive oil for a rich and flavorful appetizer."
              },
              friedPepper: {
                name: "Fried Pepper",
                description: "Crispy fried peppers, seasoned with spices for a flavorful kick."
              },
              friedAubergine: {
                name: "Fried Aubergine",
                description: "Slices of eggplant, lightly battered and fried until golden and crispy."
              },
              friedHotPepper: {
                name: "Fried Hot Pepper",
                description: "Spicy hot peppers, deep-fried to perfection for a fiery appetizer."
              },
              friedPotatoLargeCuts: {
                name: "Fried Potato Large Cuts",
                description: "Large cuts of potato, fried until crispy and golden brown, served with a choice of dipping sauce."
              },
              mixedFries: {
                name: "Mixed Fries",
                description: "A delightful combination of fried potatoes, aubergines, and peppers, served with your choice of dipping sauce."
              }
            },
            beverages: {
              name: "Beverages",
              sodas: {
                name: "Sodas",
                description: "A selection of classic sodas to quench your thirst."
              },
              karkade: {
                name: "Karkade",
                description: "A traditional Egyptian red tea made from hibiscus flowers, offering a tart, cranberry-like flavor. This native Egyptian beverage is a delightful alternative to traditional teas."
              },
              blackTea: {
                name: "Black Tea",
                description: "Our Celani Black Tea is a tribute to multicultural tea traditions, offering a robust and aromatic experience."
              }
            },            
                        
          dietTags: {
            vegan: "Vegan",
            glutenFree: "Gluten-Free",
            lowCarb: "Low-Carb",
            keto: "Keto",
            lactoseFree: "Lactose-Free",
            pescatarian: "Pescatarian",
            meat: "Meat",
            vegetarian: "Vegetarian",
            veganOptionAvailable: "Vegan option available"
          },
        }
      }
    },
    no: {
      translation: {
        headerTitle: 'Proteinrike Imperium Av Dr. Moe',
        getStarted: { message1: 'For cateringtjenester eller andre henvendelser, vennligst kontakt oss på følgende: ' },
        nav_bar: {
          home: "Hjem",
          menu: "Meny",
          services: "Cateringtjenester",
          career: "Karriere",
          contact_us: "Kontakt Oss",
        },
        hero: {
          title: "Nyt egyptiske gategleder: Der smakfulle, sunne og unike smaker vandrer på hjul",
          description1: "Dykke inn i vår meny av rike veganske alternativer, keto-vennlige gleder, og smakfulle kjøttretter, alle tilberedt med en unik, sunn vri.",
          description2: "Nyt den autentiske smaken av Egypt, nøye håndverket for å møte dine kostholdsbehov og friste smaksløkene dine, nå praktisk talt på hjul.",
          cta: "Kom i gang",
        },
        menuCard: {
          dietTags: {
            vegan: "Vegansk",
            glutenFree: "Glutenfri",
            lowCarb: "Lavkarbo",
            keto: "Keto",
            lactoseFree: "Laktosefri",
            pescatarian: "Pescatarian",
            meat: "Kjøtt",
            vegetarian: "Vegetar",
            veganOptionAvailable: "Vegansk alternativ tilgjengelig"
          },
          breads: {
            Bread: "Brød",
            baladiBruntBrod: {
              name: "Baladi Brunt Brød (4 stk)",
              description: "Et hjertevarmt blanding av kli, hele kornmel og hvete, perfekt for dypping og sandwicher, gir rikelig med fiber."
            },
            baladiHvittBrod: {
              name: "Baladi Hvitt Brød (4 stk)",
              description: "Et lettere, luftig alternativ for de som foretrekker en mer tradisjonell base for måltidene sine."
            },
            ketoBrod: {
              name: "Keto Brød",
              description: "Lavkarbo, høy-fett brød som ikke går på kompromiss med tekstur eller smak, perfekt for de som følger en lavkarbo eller keto diett; det er også naturlig glutenfritt."
            }
          },   
            mainCourses: {
              "Name": "Hovedretter",
              "fulMedames": {
                "name": "Ful Medames",
                "description": "En grunnstein i egyptisk gatemat, saktekokte favabønner krydret med tahini, krydder, sitron og olivenolje."
              },
              "makaronaBashamilMincedMeat": {
                "name": "Makarona Bashamil (kjøttdeig)",
                "description": "En trøstende pasta gratinert med en rik, kremet béchamelsaus karamellisert på toppen i ovnen, tilgjengelig i to versjoner: med kjøttdeig, eller en sjømatvariant med reker, blomkål, brokkoli og rismel som et pescatarian, glutenfritt og laktosefritt alternativ."
              },
              "makaronaBashamilShrimp": {
                "name": "Makarona Bashamil (reker)",
                "description": "En trøstende pasta gratinert med en rik, kremet béchamelsaus karamellisert på toppen i ovnen, tilgjengelig i to versjoner: med kjøttdeig, eller en sjømatvariant med reker, blomkål, brokkoli og rismel som et pescatarian, glutenfritt og laktosefritt alternativ."
              },
              "hawawshi": {
                "name": "Hawawshi",
                "description": "Krydret kjøttdeig fylt i egyptisk baladibrød, grillet til perfeksjon."
              },
              "fattaOriginal": {
                "name": "Fatta Original",
                "description": "En festlig rett med ris, sprøstekt brød og kjøtt lagvis toppet med en hvitløk-eddiksaus og buljong, tilgjengelig i kjøtteter og vegetariske versjoner der kjøtt er erstattet med aubergine."
              },
              "fattaVegan": {
                "name": "Fatta Vegan",
                "description": "En festlig rett med ris, sprøstekt brød og kjøtt lagvis toppet med en hvitløk-eddiksaus og buljong, tilgjengelig i kjøtteter og vegetariske versjoner der kjøtt er erstattet med aubergine."
              },
              "fatayer": {
                "name": "Fatayer",
                "description": "Smakfulle egyptiske paier fylt med et valg av grønnsaksblanding eller krydret kjøtt, bakt til gyllen perfeksjon."
              },
              "koshary": {
                "name": "Koshary",
                "description": "En herlig blanding av linser, ris, pasta og en krydret tomatsaus, toppet med sprøstekt løk. En elsket vegansk rett som er like næringsrik som den er deilig."
              },
              "tamya": {
                "name": "Tamya",
                "description": "I motsetning til Falafel, som er 99% kikerter, er Tamya laget av favabønner, eltet og sentrifugert til nesten flytende form, noe som resulterer i en sprø utside og myk/fluffy innside."
              },
              "sogokAlexandria": {
                "name": "Sogok Alexandria",
                "description": "Krydret kjøttdeig i lammetarmer, tilbudt i to regionale varianter, hver med sin unike blanding av krydder og koketeknikker. Cairo-versjonen er laget med karamellisert løk og små potetbiter (Yakhni); Alexandria-versjonen har en annen blanding av grønnsaker og krydder."
              },
              "sogokKairo": {
                "name": "Sogok Kairo",
                "description": "Krydret kjøttdeig i lammetarmer, tilbudt i to regionale varianter, hver med sin unike blanding av krydder og koketeknikker. Cairo-versjonen er laget med karamellisert løk og små potetbiter (Yakhni); Alexandria-versjonen har en annen blanding av grønnsaker og krydder."
              },
              "alexandrianKebda": {
                "name": "Alexandrian Kebda",
                "description": "Mør, krydret lever kokt til perfeksjon, en velsmakende godbit for offalentusiaster."
              },
              "alexandrianPulledSteakMix": {
                "name": "Alexandrian Pulled Steak Mix",
                "description": "Fine skiver av biff, grillet med en blanding av krydder og grønnsaker og trimmet til små biter, fungerer som en smakfull, hjertevarmende fylling for smørbrød."
              },
              "stektOgGrilletBoltiFisk": {
                "name": "Stekt og Grillet Bolti Fisk (Nilen Tilapia)",
                "description": "Krydret og kokt til perfeksjon, tilbyr en smak av Nilen bounty."
              },
              "stektOgGrilletHummer": {
                "name": "Stekt og Grillet Hummer",
                "description": "Luksuriøs og smakfull, tilberedt med krydder som komplementerer dens sødme."
              },
              "egyptiskKofta": {
                "name": "Egyptisk Kofta",
                "description": "Grillet kjøttdeig med rikt fettinnhold, tomat, friske urter og krydder, stangformet, smørbelagt, grillet til sprø utenpå og saftig og rik inni."
              }
            },
            desserts: {
              name: "Desserter",
              egyptianCactusFruit: {
                name: "Egyptisk Kaktusfrukt (Skrellet)",
                description: "Dykk inn i det eksotiske med vår skrellede egyptiske kaktusfrukt, som tilbyr en forfriskende søt godbit."
              },
              zalabya: {
                name: "Zalabya (Stekte Egyptiske Paiboller)",
                description: "Disse lette og luftige søte kulene er dyppet i sirup, enten drysset med sukker eller dryppet med honning, og skaper en perfekt blanding av sødme og tekstur."
              },
              konafaBelKeshta: {
                name: "Konafa Bel Keshta",
                description: "Opplev den ultimate desserten med vår Konafa Bel Keshta, som har en glatt, kremet fylling innkapslet i sprø, gyldne kunafa-nudler."
              }
            },
            appetizersAndSides: {
              name: "Forretter og Sideretter",
              bazinjanPickled: {
                name: "Bazinjan Pickled",
                description: "En deilig syltet auberginerett, krydret med urter og krydder for en frisk smak."
              },
              salad: {
                name: "Salat",
                description: "En frisk og sprø blanding av sesongens grønnsaker, vendt i en lett vinaigrette dressing."
              },
              tahini: {
                name: "Tahini",
                description: "En glatt og kremaktig pasta laget av malt sesamfrø, ofte brukt som dipp eller saus."
              },
              babaGanoush: {
                name: "Baba Ganoush",
                description: "En røykfylt auberginedipp, blandet med tahini, hvitløk, sitronsaft og olivenolje for en rik og smaksrik forrett."
              },
              friedPepper: {
                name: "Stekte Paprika",
                description: "Sprø stekte paprika, krydret med krydder for en smaksrik smak."
              },
              friedAubergine: {
                name: "Stekt Aubergine",
                description: "Skiver av aubergine, lett panert og stekt til gyllenbrun og sprø."
              },
              friedHotPepper: {
                name: "Stekt Hot Pepper",
                description: "Krydret hete paprika, frityrstekt til perfeksjon for en ildfull forrett."
              },
              friedPotatoLargeCuts: {
                name: "Stekte Store Potetskiver",
                description: "Store skiver av potet, stekt til sprø og gyldenbrun, servert med valgfri dipp."
              },
              mixedFries: {
                name: "Blandede Fries",
                description: "En herlig blanding av stekte poteter, auberginer og paprika, servert med valgfri dipp."
              }
            },
            beverages: {
              name: "Drikkevarer",
              sodas: {
                name: "Brus (Cola, Fanta, etc.)",
                description: "Et utvalg av klassiske brus for å slukke tørsten."
              },
              karkade: {
                name: "Karkade",
                description: "En tradisjonell egyptisk rød te laget av hibiskusblomster, som tilbyr en syrlig, tranebærliknende smak. Denne innfødte egyptiske drikken er et herlig alternativ til tradisjonell te."
              },
              blackTea: {
                name: "Svart te",
                description: "Vår Celani Black Tea er en hyllest til flerkulturelle te-tradisjoner og tilbyr en robust og aromatisk opplevelse."
              }
            }          
            
          },
        
          
      }
    },
    pl: {
        translation: {
          headerTitle: 'Imperium Białka By Dr. Moe',
          getStarted: { message1: 'Dla usług cateringowych lub innych zapytań prosimy o kontakt pod następujący adres: ' },
          nav_bar: {
            home: "Strona główna",
            menu: "Menu",
            services: "Usługi Cateringu",
            career: "Kariera",
            contact_us: "Skontaktuj się z nami",
          },
          hero: {
            title: "Pozwól sobie na Egipskie Rozkosze uliczne: Gdzie Smakowite, Zdrowe i Unikalne Smaki Wędrują na Kołach",
            description1: "Zanurz się w naszym menu bogatych opcji wegańskich, pyszności przyjaznych dla diety keto i smacznych mięsnych przysmaków, wszystkich przygotowanych z wyjątkowym, zdrowym obrotem.",
            description2: "Ciesz się autentycznym smakiem Egiptu, starannie przygotowanym, aby zaspokoić Twoje potrzeby dietetyczne i podniecić Twoje kubki smakowe, teraz wygodnie na kołach.",
            cta: "Rozpocznij",
          },
          menuCard: {
              "mainCourses": {
                "Name": "Dania Główne",
                "fulMedames": {
                  "name": "Ful Medames",
                  "description": "Podstawa egipskiego jedzenia ulicznego, wolno gotowane bób przyprawione tahini, przyprawami, cytryną i oliwą z oliwek."
                },
                "makaronaBashamilMincedMeat": {
                  "name": "Makarona Bashamil (z mielonym mięsem)",
                  "description": "Pocieszające zapiekanki makaronowe z bogatym, kremowym sosem beszamelowym skarmelizowanym na wierzchu, dostępne w dwóch wersjach: z mielonym mięsem lub odmianą z owocami morza z krewetkami, kalafiorem, brokułami i mąką ryżową jako alternatywa bezlaktozowa, bezglutenowa i peskatariańska."
                },
                "makaronaBashamilShrimp": {
                  "name": "Makarona Bashamil (z krewetkami)",
                  "description": "Pocieszające zapiekanki makaronowe z bogatym, kremowym sosem beszamelowym skarmelizowanym na wierzchu, dostępne w dwóch wersjach: z mielonym mięsem lub odmianą z owocami morza z krewetkami, kalafiorem, brokułami i mąką ryżową jako alternatywa bezlaktozowa, bezglutenowa i peskatariańska."
                },
                "hawawshi": {
                  "name": "Hawawshi",
                  "description": "Pikantne mielone mięso umieszczone w egipskim chlebie baladi, grillowane do chrupkości."
                },
                "fattaOriginal": {
                  "name": "Fatta Oryginalna",
                  "description": "Świąteczne danie z ryżem, chrupiącym pieczonym chlebem i mięsem ułożonym warstwami, polane sosem z czosnku i octu oraz bulionem, dostępne w wersji mięsnej i wegetariańskiej, gdzie mięso zastąpione jest bakłażanem."
                },
                "fattaVegan": {
                  "name": "Fatta Wege",
                  "description": "Świąteczne danie z ryżem, chrupiącym pieczonym chlebem i mięsem ułożonym warstwami, polane sosem z czosnku i octu oraz bulionem, dostępne w wersji mięsnej i wegetariańskiej, gdzie mięso zastąpione jest bakłażanem."
                },
                "fatayer": {
                  "name": "Fatayer",
                  "description": "Pyszne egipskie ciasteczka nadziewane mieszanką warzyw lub doprawionym mięsem, pieczone do złota."
                },
                "koshary": {
                  "name": "Koshary",
                  "description": "Przepyszna mieszanka soczewicy, ryżu, makaronu i pikantnego sosu pomidorowego, zwieńczona chrupiącą cebulą. Ukochane danie wegańskie, tak odżywcze, jak smaczne."
                },
                "tamya": {
                  "name": "Tamya",
                  "description": "W przeciwieństwie do falafela, który składa się w 99% z ciecierzycy, Tamya jest robiona z bobu, ugniatana i odśrodkowana do prawie płynnej formy, co skutkuje chrupiącą zewnętrzną warstwą i miękkim/puszystym wnętrzem."
                },
                "sogokAlexandria": {
                  "name": "Sogok Aleksandria",
                  "description": "Krydzone mielone mięso w jagnięcych osłonkach, oferowane w dwóch regionalnych wariantach, każdy z unikalną mieszanką przypraw i technik gotowania. Wersja kairska jest przygotowywana z karmelizowaną cebulą i małymi kostkami ziemniaków (Yakhni); wersja aleksandryjska zawiera inną mieszankę warzyw i przypraw."
                },
                "sogokKairo": {
                  "name": "Sogok Kair",
                  "description": "Krydzone mielone mięso w jagnięcych osłonkach, oferowane w dwóch regionalnych wariantach, każdy z unikalną mieszanką przypraw i technik gotowania. Wersja kairska jest przygotowywana z karmelizowaną cebulą i małymi kostkami ziemniaków (Yakhni); wersja aleksandryjska zawiera inną mieszankę warzyw i przypraw."
                },
                "alexandrianKebda": {
                  "name": "Aleksandryjska Kebda",
                  "description": "Delikatna, krydzone wątróbka gotowana do perfekcji, pyszny przysmak dla miłośników podrobów."
                },
                "alexandrianPulledSteakMix": {
                  "name": "Aleksandryjski Mix Steków",
                  "description": "Cienkie plasterki wołowiny, grillowane z mieszanką przypraw i warzyw, pokrojone na małe kawałki, służące jako smakowite, rozgrzewające serce nadzienie do kanapek."
                },
                "stektOgGrilletBoltiFisk": {
                  "name": "Smażona i Grillowana Ryba Bolti (Tilapia z Nilu)",
                  "description": "Przyprawiona i ugotowana do perfekcji, oferuje smak darów Nilu."
                },
                "stektOgGrilletHummer": {
                  "name": "Smażony i Grillowany Homar",
                  "description": "Luksusowy i pełen smaku, przygotowany z przyprawami, które dopełniają jego słodycz."
                },
                "egyptiskKofta": {
                  "name": "Egipska Kofta",
                  "description": "Grillowane mielone mięso z dużą zawartością tłuszczu, pomidor, świeże zioła i przyprawy, w kształcie pręta, posmarowane masłem, grillowane do chrupkości na zewnątrz i soczystości w środku."
                }
              },
              "breads": {
                "Bread": "Pieczywo",
                "baladiBruntBrod": {
                  "name": "Chleb Baladi Brązowy (4 sztuki)",
                  "description": "Solidna mieszanka otrębów, mąki z pełnego ziarna kukurydzy i mąki pszennej, idealna do maczania i kanapek, zapewniająca obfitość błonnika."
                },
                "baladiHvittBrod": {
                  "name": "Chleb Baladi Biały (4 sztuki)",
                  "description": "Lżejsza, bardziej powietrzna opcja dla tych, którzy preferują bardziej tradycyjną bazę dla swoich posiłków."
                },
                "ketoBrod": {
                  "name": "Chleb Keto",
                  "description": "Chleb niskowęglowodanowy, wysokotłuszczowy, który nie kompromituje tekstury ani smaku, idealny dla osób na diecie niskowęglowodanowej lub ketogenicznej; jest również naturalnie bezglutenowy."
                }
              },
            dietTags: {
              vegan: "Wegańska",
              glutenFree: "Bezglutenowa",
              lowCarb: "Niskowęglowodanowa",
              keto: "Keto",
              lactoseFree: "Bez laktozy",
              pescatarian: "Peskatariańska",
              meat: "Mięsna",
              vegetarian: "Wegetariańska",
              veganOptionAvailable: "Dostępna opcja wegańska"
            },
            desserts: {
              name: "Desery",
              egyptianCactusFruit: {
                name: "Egipski Owoc Kaktusa (Obrany)",
                description: "Zanurz się w egzotyce z naszym obranym egipskim owocem kaktusa, oferującym orzeźwiającą słodką przekąskę."
              },
              zalabya: {
                name: "Zalabya (Smażone Egipskie Kulki Ciasta)",
                description: "Te lekkie i puszyste słodkie kule są maczane w syropie, posypane cukrem lub polane miodem, tworząc doskonałe połączenie słodyczy i tekstury."
              },
              konafaBelKeshta: {
                name: "Konafa Bel Keshta",
                description: "Zasmakuj prawdziwej doskonałości z naszą Konafa Bel Keshta, w której gładkie, kremowe nadzienie jest otoczone chrupiącymi, złotymi makaronami kunafa."
              }
            },
            appetizersAndSides: {
              name: "Przystawki i Dodatki",
              bazinjanPickled: {
                name: "Bazinjan Pickled",
                description: "Pyszne marynowane bakłażany, przyprawione ziołami i przyprawami dla świeżego smaku."
              },
              salad: {
                name: "Sałatka",
                description: "Świeża i chrupiąca mieszanka warzyw sezonowych, skropiona lekkim sosem vinegret."
              },
              tahini: {
                name: "Tahini",
                description: "Gładka i kremowa pasta zmielonych nasion sezamu, często używana jako dip lub sos."
              },
              babaGanoush: {
                name: "Baba Ganoush",
                description: "Wędzony dip z bakłażana, zmieszany z tahini, czosnkiem, sokiem z cytryny i oliwą z oliwek, dla bogatego i aromatycznego smaku."
              },
              friedPepper: {
                name: "Smażona Papryka",
                description: "Chrupiąca smażona papryka, przyprawiona przyprawami dla aromatycznego smaku."
              },
              friedAubergine: {
                name: "Smażony Bakłażan",
                description: "Plasterki bakłażana, lekko panierowane i smażone na złoty kolor i chrupkość."
              },
              friedHotPepper: {
                name: "Smażona Ostra Papryka",
                description: "Ostra papryka, głęboko smażona do perfekcji, dla ognistego smaku przystawki."
              },
              friedPotatoLargeCuts: {
                name: "Duże Krokiety z Ziemniaków",
                description: "Duże kawałki ziemniaków, smażone na chrupko i złoty kolor, podawane z wyborem sosu."
              },
              mixedFries: {
                name: "Mieszane Frytki",
                description: "Pyszna kombinacja smażonych ziemniaków, bakłażanów i papryki, podawana z wyborem sosu."
              }
            },
            beverages: {
              name: "Napoje",
              sodas: {
                name: "Napoje gazowane (Cola, Fanta, itp.)",
                description: "Wybór klasycznych napojów gazowanych, by ugasić pragnienie."
              },
              karkade: {
                name: "Karkade",
                description: "Tradycyjna egipska czerwona herbata wykonana z kwiatów hibiskusa, oferująca kwaśny, przypominający żurawinę smak. Ten rdzenny egipski napój to urocza alternatywa dla tradycyjnych herbat."
              },
              blackTea: {
                name: "Czarna herbata",
                description: "Nasza czarna herbata Celani to hołd dla wielokulturowych tradycji herbacianych, oferująca wytrawną i aromatyczną przyjemność."
              }
            }
            
            
          }
          
        }
      },
    de: {
      translation: {
        headerTitle: 'Proteinreiches Reich von Dr. Moe',
        getStarted: { message1: 'Für Catering-Dienstleistungen oder andere Anfragen erreichen Sie uns unter folgender Adresse: ' },
        nav_bar: {
          home: "Startseite",
          menu: "Menü",
          services: "Catering-Dienste",
          career: "Karriere",
          contact_us: "Kontakt",
        },
        hero: {
          title: "Genießen Sie ägyptische Straßenköstlichkeiten: Wo leckere, gesunde und einzigartige Aromen auf Rädern umherziehen",
          description1: "Tauchen Sie ein in unser Menü mit reichhaltigen veganen Optionen, keto-freundlichen Leckerbissen und herzhaften Fleischgerichten, alle mit einer einzigartigen, gesunden Drehung zubereitet.",
          description2: "Genießen Sie den authentischen Geschmack Ägyptens, sorgfältig zubereitet, um Ihren Ernährungsbedürfnissen gerecht zu werden und Ihre Geschmacksknospen zu begeistern, jetzt bequem auf Rädern.",
          cta: "Loslegen",
        },
        menuCard : {

          dietTags: {
            vegan: "Vegan",
            glutenFree: "Glutenfrei",
            lowCarb: "Low-Carb",
            keto: "Keto",
            lactoseFree: "Laktosefrei",
            pescatarian: "Pescetarisch",
            meat: "Fleisch",
            vegetarian: "Vegetarisch",
            veganOptionAvailable: "Vegane Option verfügbar"
          },
          breads: {
            Bread: "Brot",
            baladiBruntBrod: {
              name: "Baladi Braunes Brot (4 Stk.)",
              description: "Eine herzhafte Mischung aus Kleie, ganzem Mais- und Weizenmehl, perfekt zum Eintauchen und für Sandwiches, bietet viel Ballaststoffe."
            },
            baladiHvittBrod: {
              name: "Baladi Weißes Brot (4 Stk.)",
              description: "Eine leichtere, luftigere Option für diejenigen, die eine traditionellere Basis für ihre Mahlzeiten bevorzugen."
            },
            ketoBrod: {
              name: "Keto Brot",
              description: "Kohlenhydratarmes, fettreiches Brot, das keine Kompromisse bei Textur oder Geschmack macht, perfekt für diejenigen, die eine kohlenhydratarme oder ketogene Diät verfolgen; es ist auch von Natur aus glutenfrei."
            }
          },
            "mainCourses": {
              "Name": "Hauptgerichte",
              "fulMedames": {
                "name": "Ful Medames",
                "description": "Ein Grundnahrungsmittel der ägyptischen Straßenküche, langsam gekochte Saubohnen gewürzt mit Tahini, Gewürzen, Zitrone und Olivenöl."
              },
              "makaronaBashamilMincedMeat": {
                "name": "Makarona Bashamil (mit Hackfleisch)",
                "description": "Ein tröstender Nudelauflauf mit einer reichen, cremigen Béchamelsauce, die im Ofen karamellisiert wird, erhältlich in zwei Varianten: mit Hackfleisch oder einer Meeresfrüchtevariante mit Garnelen, Blumenkohl, Brokkoli und Reismehl als pescetarische, glutenfreie und laktosefreie Alternative."
              },
              "makaronaBashamilShrimp": {
                "name": "Makarona Bashamil (mit Garnelen)",
                "description": "Ein tröstender Nudelauflauf mit einer reichen, cremigen Béchamelsauce, die im Ofen karamellisiert wird, erhältlich in zwei Varianten: mit Hackfleisch oder einer Meeresfrüchtevariante mit Garnelen, Blumenkohl, Brokkoli und Reismehl als pescetarische, glutenfreie und laktosefreie Alternative."
              },
              "hawawshi": {
                "name": "Hawawshi",
                "description": "Scharfes Hackfleisch gefüllt in ägyptischem Baladi-Brot, gegrillt bis zur Perfektion."
              },
              "fattaOriginal": {
                "name": "Fatta Original",
                "description": "Ein festliches Gericht mit Reis, knusprig gebratenem Brot und Fleisch, geschichtet und gekrönt mit einer Knoblauch-Essig-Sauce und Brühe, erhältlich in Fleischfresser- und vegetarischen Versionen, bei denen das Fleisch durch Auberginen ersetzt wird."
              },
              "fattaVegan": {
                "name": "Fatta Vegan",
                "description": "Ein festliches Gericht mit Reis, knusprig gebratenem Brot und Fleisch, geschichtet und gekrönt mit einer Knoblauch-Essig-Sauce und Brühe, erhältlich in Fleischfresser- und vegetarischen Versionen, bei denen das Fleisch durch Auberginen ersetzt wird."
              },
              "fatayer": {
                "name": "Fatayer",
                "description": "Geschmackvolle ägyptische Pies gefüllt mit einer Auswahl an Gemüsemischung oder gewürztem Fleisch, gebacken zur goldenen Perfektion."
              },
              "koshary": {
                "name": "Koshary",
                "description": "Eine köstliche Mischung aus Linsen, Reis, Nudeln und einer scharfen Tomatensauce, gekrönt mit knusprigen Zwiebeln. Ein beliebtes veganes Gericht, das so nahrhaft wie lecker ist."
              },
              "tamya": {
                "name": "Tamya",
                "description": "Im Gegensatz zu Falafel, die zu 99% aus Kichererbsen besteht, wird Tamya aus Saubohnen hergestellt, geknetet und zu einer fast flüssigen Form zentrifugiert, was zu einer knusprigen Außenseite und einem weichen/fluffigen Inneren führt."
              },
              "sogokAlexandria": {
                "name": "Sogok Alexandria",
                "description": "Gewürztes Hackfleisch in Lammhüllen, angeboten in zwei regionalen Varianten, jede mit ihrer einzigartigen Mischung aus Gewürzen und Kochtechniken. Die Kairo-Version wird mit karamellisierten Zwiebeln und winzigen Kartoffelwürfeln (Yakhni) zubereitet; die Alexandria-Version hat eine andere Mischung aus Gemüse und Gewürzen."
              },
              "sogokKairo": {
                "name": "Sogok Kairo",
                "description": "Gewürztes Hackfleisch in Lammhüllen, angeboten in zwei regionalen Varianten, jede mit ihrer einzigartigen Mischung aus Gewürzen und Kochtechniken. Die Kairo-Version wird mit karamellisierten Zwiebeln und winzigen Kartoffelwürfeln (Yakhni) zubereitet; die Alexandria-Version hat eine andere Mischung aus Gemüse und Gewürzen."
              },
              "alexandrianKebda": {
                "name": "Alexandrian Kebda",
                "description": "Zarte, gewürzte Leber, gekocht zur Perfektion, ein schmackhafter Leckerbissen für Innereien-Liebhaber."
              },
              "alexandrianPulledSteakMix": {
                "name": "Alexandrian Pulled Steak Mix",
                "description": "Feine Scheiben von Rindfleisch, gegrillt mit einer Mischung aus Gewürzen und Gemüse und zu kleinen Stücken geschnitten, dienen als schmackhafte, herzerwärmende Füllung für Sandwiches."
              },
              "stektOgGrilletBoltiFisk": {
                "name": "Gebratener und Gegrillter Bolti Fisch (Nil-Tilapia)",
                "description": "Gewürzt und zur Perfektion gekocht, bietet einen Geschmack des Nils."
              },
              "stektOgGrilletHummer": {
                "name": "Gebratener und Gegrillter Hummer",
                "description": "Luxuriös und geschmackvoll, zubereitet mit Gewürzen, die seine Süße ergänzen."
              },
              "egyptiskKofta": {
                "name": "Ägyptische Kofta",
                "description": "Gegrilltes Hackfleisch mit hohem Fettgehalt, Tomate, frischen Kräutern und Gewürzen, stangenförmig, butterbeschichtet, außen knusprig und innen saftig und reichhaltig gegrillt."
              }
            }   ,
            desserts: {
              name: "Desserts",
              egyptianCactusFruit: {
                name: "Ägyptische Kaktusfrucht (Geschält)",
                description: "Tauchen Sie ein in das Exotische mit unserer geschälten ägyptischen Kaktusfrucht, die eine erfrischende süße Leckerei bietet."
              },
              zalabya: {
                name: "Zalabya (Gebratene Ägyptische Teigbällchen)",
                description: "Diese leichten und luftigen süßen Bällchen werden in Sirup getaucht, entweder mit Zucker bestreut oder mit Honig beträufelt und bieten eine perfekte Mischung aus Süße und Textur."
              },
              konafaBelKeshta: {
                name: "Konafa Bel Keshta",
                description: "Erleben Sie das ultimative Dessert mit unserer Konafa Bel Keshta, die eine glatte, cremige Füllung in knusprigen, goldenen Kunafa-Nudeln bietet."
              }
            },
            appetizersAndSides: {
              name: "Vorspeisen und Beilagen",
              bazinjanPickled: {
                name: "Bazinjan Pickled",
                description: "Ein köstliches eingelegtes Auberginengericht, gewürzt mit Kräutern und Gewürzen für einen erfrischenden Geschmack."
              },
              salad: {
                name: "Salat",
                description: "Eine frische und knackige Mischung aus saisonalem Gemüse, geworfen in einem leichten Vinaigrette-Dressing."
              },
              tahini: {
                name: "Tahini",
                description: "Eine glatte und cremige Paste aus gemahlenen Sesamsamen, oft als Dip oder Sauce verwendet."
              },
              babaGanoush: {
                name: "Baba Ganoush",
                description: "Ein rauchiger Auberginen-Dip, gemischt mit Tahini, Knoblauch, Zitronensaft und Olivenöl für einen reichen und geschmackvollen Appetizer."
              },
              friedPepper: {
                name: "Gebratene Paprika",
                description: "Knusprig gebratene Paprika, gewürzt mit Gewürzen für einen geschmackvollen Kick."
              },
              friedAubergine: {
                name: "Gebratene Aubergine",
                description: "Scheiben von Auberginen, leicht paniert und goldbraun frittiert."
              },
              friedHotPepper: {
                name: "Gebratene Scharfe Paprika",
                description: "Scharfe, frittierte Paprika perfektioniert für einen feurigen Appetizer."
              },
              friedPotatoLargeCuts: {
                name: "Große Pommes Frites",
                description: "Große Pommes Frites, knusprig und goldbraun frittiert, serviert mit einer Auswahl an Dips."
              },
              mixedFries: {
                name: "Gemischte Pommes Frites",
                description: "Eine köstliche Kombination aus Pommes Frites, Auberginen und Paprika, serviert mit Ihrer Wahl an Dip."
              }
            },
            beverages: {
              name: "Getränke",
              sodas: {
                name: "Limettengetränke (Cola, Fanta, etc.)",
                description: "Eine Auswahl klassischer Limettengetränke, um Ihren Durst zu stillen."
              },
              karkade: {
                name: "Karkade",
                description: "Ein traditioneller ägyptischer roter Tee aus Hibiskusblüten, der einen herb-säuerlichen, an Preiselbeeren erinnernden Geschmack bietet. Dieses einheimische ägyptische Getränk ist eine herrliche Alternative zu traditionellem Tee."
              },
              blackTea: {
                name: "Schwarzer Tee",
                description: "Unser Celani Schwarzer Tee ist eine Hommage an multikulturelle Teetraditionen und bietet ein robustes und aromatisches Erlebnis."
              }
            }
                                   
        }     
      }
    },
    es: {
      translation: {
        headerTitle: 'Imperio de Proteínas por Dr. Moe',
        getStarted: { message1: 'Para servicios de catering u otras consultas, contáctenos en lo siguiente: ' },
        nav_bar: {
          home: "Inicio",
          menu: "Menú",
          services: "Servicios de Catering",
          career: "Carrera",
          contact_us: "Contáctanos",
        },
        hero: {
          title: "Déjate seducir por los placeres de la calle egipcia: donde sabores sabrosos, saludables y únicos deambulan sobre ruedas",
          description1: "Sumérgete en nuestro menú de ricas opciones veganas, delicias aptas para keto y tratamientos carnívoros sabrosos, todos preparados con un giro único y saludable.",
          description2: "Disfruta del auténtico sabor de Egipto, meticulosamente elaborado para satisfacer tus necesidades dietéticas y deleitar tu paladar, ahora convenientemente sobre ruedas.",
          cta: "Empezar",
        },
        menuCard : {
          dietTags: {
            vegan: "Vegano",
            glutenFree: "Sin gluten",
            lowCarb: "Bajo en carbohidratos",
            keto: "Keto",
            lactoseFree: "Sin lactosa",
            pescatarian: "Pescetariano",
            meat: "Carne",
            vegetarian: "Vegetariano",
            veganOptionAvailable: "Opción vegana disponible"
          },
          breads: {
            Bread: "Pan",
            baladiBruntBrod: {
              name: "Pan Marrón Baladi (4 piezas)",
              description: "Una mezcla saludable de salvado, harina de maíz entero y harina de trigo, perfecto para mojar y sándwiches, proporcionando mucha fibra."
            },
            baladiHvittBrod: {
              name: "Pan Blanco Baladi (4 piezas)",
              description: "Una opción más ligera y aireada para aquellos que prefieren una base más tradicional para sus comidas."
            },
            ketoBrod: {
              name: "Pan Keto",
              description: "Pan bajo en carbohidratos y alto en grasas que no compromete la textura o el sabor, perfecto para aquellos que siguen una dieta baja en carbohidratos o cetogénica; también es naturalmente libre de gluten."
            }
          },
          "mainCourses": {
            "Name": "Platos Principales",
            "fulMedames": {
              "name": "Ful Medames",
              "description": "Un básico de la comida callejera egipcia, habas cocidas a fuego lento con tahini, especias, limón y aceite de oliva."
            },
            "makaronaBashamilMincedMeat": {
              "name": "Makarona Bashamil (carne picada)",
              "description": "Un reconfortante gratinado de pasta con una rica y cremosa salsa bechamel caramelizada en la parte superior en el horno, disponible en dos versiones: con carne picada o una variante de mariscos con camarones, coliflor, brócoli y harina de arroz como alternativa pescetariana, sin gluten y sin lactosa."
            },
            "makaronaBashamilShrimp": {
              "name": "Makarona Bashamil (camarones)",
              "description": "Un reconfortante gratinado de pasta con una rica y cremosa salsa bechamel caramelizada en la parte superior en el horno, disponible en dos versiones: con carne picada o una variante de mariscos con camarones, coliflor, brócoli y harina de arroz como alternativa pescetariana, sin gluten y sin lactosa."
            },
            "hawawshi": {
              "name": "Hawawshi",
              "description": "Carne picada picante rellena en pan baladi egipcio, asado a la perfección."
            },
            "fattaOriginal": {
              "name": "Fatta Original",
              "description": "Un plato festivo con arroz, pan frito crujiente y carne, apilados y cubiertos con una salsa de ajo y vinagre y caldo, disponible en versiones carnívoras y vegetarianas donde la carne se sustituye por berenjenas."
            },
            "fattaVegan": {
              "name": "Fatta Vegana",
              "description": "Un plato festivo con arroz, pan frito crujiente y carne, apilados y cubiertos con una salsa de ajo y vinagre y caldo, disponible en versiones carnívoras y vegetarianas donde la carne se sustituye por berenjenas."
            },
            "fatayer": {
              "name": "Fatayer",
              "description": "Sabrosas empanadas egipcias rellenas de una selección de mezcla de verduras o carne sazonada, horneadas hasta la perfección dorada."
            },
            "koshary": {
              "name": "Koshary",
              "description": "Una deliciosa mezcla de lentejas, arroz, pasta y una salsa de tomate picante, cubierta con cebollas crujientes. Un plato vegano muy querido que es tan nutritivo como delicioso."
            },
            "tamya": {
              "name": "Tamya",
              "description": "A diferencia del falafel, que es 99% de garbanzos, la tamya está hecha de habas, amasadas y centrifugadas hasta casi formar una masa líquida, lo que resulta en un exterior crujiente y un interior suave y esponjoso."
            },
            "sogokAlexandria": {
              "name": "Sogok Alexandria",
              "description": "Carne picada condimentada en tripas de cordero, ofrecida en dos variaciones regionales, cada una con su propia mezcla única de especias y técnicas culinarias. La versión de El Cairo está hecha con cebollas caramelizadas y cubitos de patata pequeños (Yakhni); la versión de Alejandría tiene una mezcla diferente de verduras y especias."
            },
            "sogokKairo": {
              "name": "Sogok Cairo",
              "description": "Carne picada condimentada en tripas de cordero, ofrecida en dos variaciones regionales, cada una con su propia mezcla única de especias y técnicas culinarias. La versión de El Cairo está hecha con cebollas caramelizadas y cubitos de patata pequeños (Yakhni); la versión de Alejandría tiene una mezcla diferente de verduras y especias."
            },
            "alexandrianKebda": {
              "name": "Hígado de Alejandría",
              "description": "Hígado condimentado y cocido a la perfección, un sabroso manjar para los entusiastas de las vísceras."
            },
            "alexandrianPulledSteakMix": {
              "name": "Mezcla de Carne Desmenuzada de Alejandría",
              "description": "Finas lonchas de carne de res, asadas con una mezcla de especias y verduras y cortadas en trozos pequeños, sirviendo como un relleno sabroso y reconfortante para sándwiches."
            },
            "stektOgGrilletBoltiFisk": {
              "name": "Pez Nilo Tilapia Frito y a la Parrilla",
              "description": "Condimentado y cocido a la perfección, ofreciendo un sabor de la abundancia del Nilo."
            },
            "stektOgGrilletHummer": {
              "name": "Langosta Frita y a la Parrilla",
              "description": "Lujosa y sabrosa, preparada con especias que complementan su dulzura."
            },
            "egyptiskKofta": {
              "name": "Kofta Egipcia",
              "description": "Carne picada a la parrilla con alto contenido de grasa, tomate, hierbas frescas y especias, en forma de barra, untada con mantequilla, asada hasta que esté crujiente por fuera y jugosa y rica por dentro."
            }
          },
          desserts: {
            name: "Postres",
            egyptianCactusFruit: {
              name: "Fruta de Cactus Egipcia (Pelada)",
              description: "Sumérgete en lo exótico con nuestra fruta de cactus egipcia pelada, que ofrece un refrescante y dulce manjar."
            },
            zalabya: {
              name: "Zalabya (Bolitas de Masa Egipcia Fritas)",
              description: "Estas ligeras y aireadas bolitas dulces están sumergidas en almíbar, ya sea espolvoreadas con azúcar o bañadas en miel, creando una perfecta combinación de dulzura y textura."
            },
            konafaBelKeshta: {
              name: "Konafa Bel Keshta",
              description: "Experimenta el postre definitivo con nuestra Konafa Bel Keshta, que presenta un relleno suave y cremoso envuelto en crujientes y doradas fideos de kunafa."
            }
          },
          appetizersAndSides: {
            name: "Aperitivos y Acompañantes",
            bazinjanPickled: {
              name: "Bazinjan Pickled",
              description: "Un delicioso plato de berenjena encurtida, sazonado con hierbas y especias para un sabor refrescante."
            },
            salad: {
              name: "Ensalada",
              description: "Una mezcla fresca y crujiente de verduras de temporada, aderezada con un ligero aliño de vinagreta."
            },
            tahini: {
              name: "Tahini",
              description: "Una pasta suave y cremosa hecha de semillas de sésamo molidas, frecuentemente utilizada como dip o salsa."
            },
            babaGanoush: {
              name: "Baba Ganoush",
              description: "Un dip de berenjena ahumada, mezclado con tahini, ajo, jugo de limón y aceite de oliva para un sabroso y aromático aperitivo."
            },
            friedPepper: {
              name: "Pimiento Frito",
              description: "Pimientos fritos crujientes, condimentados con especias para un toque de sabor."
            },
            friedAubergine: {
              name: "Berenjena Frita",
              description: "Rodajas de berenjena, ligeramente rebozadas y fritas hasta que estén doradas y crujientes."
            },
            friedHotPepper: {
              name: "Pimiento Picante Frito",
              description: "Pimientos picantes fritos a la perfección para un aperitivo ardiente."
            },
            friedPotatoLargeCuts: {
              name: "Patatas Fritas Grandes",
              description: "Grandes cortes de patata, fritas hasta que estén crujientes y doradas, servidas con una selección de salsas."
            },
            mixedFries: {
              name: "Patatas Fritas Mixtas",
              description: "Una deliciosa combinación de patatas fritas, berenjenas y pimientos, servida con su elección de salsa."
            }
          },
          beverages: {
            name: "Bebidas",
            sodas: {
              name: "Refrescos (Cola, Fanta, etc.)",
              description: "Una selección de refrescos clásicos para saciar tu sed."
            },
            karkade: {
              name: "Karkade",
              description: "Un té rojo tradicional egipcio hecho de flores de hibisco, que ofrece un sabor agrio similar al arándano. Esta bebida egipcia nativa es una deliciosa alternativa a los tés tradicionales."
            },
            blackTea: {
              name: "Té negro",
              description: "Nuestro Té Negro Celani es un homenaje a las tradiciones multiculturales del té, ofreciendo una experiencia robusta y aromática."
            }
          }
                         
        }
        
      }
    },
    it: {
      translation: {
        headerTitle: 'Impero Proteico Di Dr. Moe',
        getStarted: { message1: 'Per servizi di catering o altri quesiti, contattateci al seguente indirizzo: ' },
        nav_bar: {
          home: "Home",
          menu: "Menù",
          services: "Servizi di Catering",
          career: "Carriera",
          contact_us: "Contattaci",
        },
        hero: {
          title: "Goditi le Delizie della Strada Egiziana: Dove i Gusti Gustosi, Sani e Unici Gironzolano su Ruote",
          description1: "Immergiti nel nostro menu ricco di opzioni vegane, delizie adatte alla dieta cheto e trattamenti carnivori saporiti, tutti preparati con un tocco unico e salutare.",
          description2: "Goditi il vero sapore dell'Egitto, meticolosamente elaborato per soddisfare le tue esigenze dietetiche e stuzzicare le tue papille gustative, ora comodamente su ruote.",
          cta: "Inizia",
        },
        menuCard: {
          dietTags: {
            vegan: "Vegano",
            glutenFree: "Senza glutine",
            lowCarb: "Basso contenuto di carboidrati",
            keto: "Keto",
            lactoseFree: "Senza lattosio",
            pescatarian: "Pesce",
            meat: "Carne",
            vegetarian: "Vegetariano",
            veganOptionAvailable: "Opzione vegana disponibile"
          },
          breads: {
            Bread: "Pane",
            baladiBruntBrod: {
              name: "Pane Marrone Baladi (4 pezzi)",
              description: "Una miscela robusta di crusca, farina di mais integrale e farina di frumento, perfetta per intingere e per sandwich, ricca di fibre."
            },
            baladiHvittBrod: {
              name: "Pane Bianco Baladi (4 pezzi)",
              description: "Un'opzione più leggera e ariosa per coloro che preferiscono una base più tradizionale per i loro pasti."
            },
            ketoBrod: {
              name: "Pane Keto",
              description: "Pane a basso contenuto di carboidrati e alto in grassi che non compromette la texture o il gusto, perfetto per chi segue una dieta a basso contenuto di carboidrati o chetogenica; è anche naturalmente senza glutine."
            }
          },
          "mainCourses": {
            "Name": "Piatti Principali",
            "fulMedames": {
              "name": "Ful Medames",
              "description": "Un pilastro della street food egiziana, fave cotte lentamente con tahini, spezie, limone e olio d'oliva."
            },
            "makaronaBashamilMincedMeat": {
              "name": "Makarona Bashamil (carne macinata)",
              "description": "Una gratinatura confortante di pasta con una ricca e cremosa salsa besciamella caramellata in cima al forno, disponibile in due versioni: carne macinata, o una variante di frutti di mare con gamberetti, cavolfiore, broccoli e farina di riso per un'alternativa pescetariana, senza glutine e senza lattosio."
            },
            "makaronaBashamilShrimp": {
              "name": "Makarona Bashamil (gamberi)",
              "description": "Una gratinatura confortante di pasta con una ricca e cremosa salsa besciamella caramellata in cima al forno, disponibile in due versioni: carne macinata, o una variante di frutti di mare con gamberetti, cavolfiore, broccoli e farina di riso per un'alternativa pescetariana, senza glutine e senza lattosio."
            },
            "hawawshi": {
              "name": "Hawawshi",
              "description": "Carne macinata speziata ripiena in pane baladi egiziano, grigliato alla perfezione."
            },
            "fattaOriginal": {
              "name": "Fatta Originale",
              "description": "Un piatto festoso con riso, pane fritto croccante e carne stratificata e coperto con salsa di aglio e aceto e brodo, disponibile in versioni carnivore e vegetariane dove la carne viene sostituita con melanzane."
            },
            "fattaVegan": {
              "name": "Fatta Vegana",
              "description": "Un piatto festoso con riso, pane fritto croccante e carne stratificata e coperto con salsa di aglio e aceto e brodo, disponibile in versioni carnivore e vegetariane dove la carne viene sostituita con melanzane."
            },
            "fatayer": {
              "name": "Fatayer",
              "description": "Gustose torte egiziane ripiene con una selezione di verdure o carne condita, cotte alla perfezione dorata."
            },
            "koshary": {
              "name": "Koshary",
              "description": "Una deliziosa miscela di lenticchie, riso, pasta e una salsa di pomodoro piccante, coperta con cipolle croccanti. Un amato piatto vegano che è nutriente quanto delizioso."
            },
            "tamya": {
              "name": "Tamya",
              "description": "A differenza del falafel, che è il 99% di ceci, la tamya è fatta con fave, impastate e centrifugate fino a quasi formare una massa liquida, risultando in un'esterno croccante e un interno morbido e soffice."
            },
            "sogokAlexandria": {
              "name": "Sogok Alessandria",
              "description": "Carne macinata speziata in budella di agnello, offerta in due varianti regionali, ognuna con la propria miscela unica di spezie e tecniche culinarie. La versione del Cairo è fatta con cipolle caramellate e cubetti di patate piccoli (Yakhni); la versione di Alessandria ha una diversa miscela di verdure e spezie."
            },
            "sogokKairo": {
              "name": "Sogok Cairo",
              "description": "Carne macinata speziata in budella di agnello, offerta in due varianti regionali, ognuna con la propria miscela unica di spezie e tecniche culinarie. La versione del Cairo è fatta con cipolle caramellate e cubetti di patate piccoli (Yakhni); la versione di Alessandria ha una diversa miscela di verdure e spezie."
            },
            "alexandrianKebda": {
              "name": "Fegato di Alessandria",
              "description": "Fegato speziato cotto alla perfezione, una deliziosa prelibatezza per gli appassionati di frattaglie."
            },
            "alexandrianPulledSteakMix": {
              "name": "Mix di Carne Tirata di Alessandria",
              "description": "Fettine sottili di manzo, grigliate con una miscela di spezie e verdure e tagliate a pezzetti piccoli, servendo come un ripieno saporito e avvolgente per panini."
            },
            "stektOgGrilletBoltiFisk": {
              "name": "Tilapia del Nilo Fritto e Grigliato",
              "description": "Condito e cotto alla perfezione, offrendo un assaggio dell'abbondanza del Nilo."
            },
            "stektOgGrilletHummer": {
              "name": "Aragosta Fritta e Grigliata",
              "description": "Lussuosa e saporita, preparata con spezie che ne esaltano la dolcezza."
            },
            "egyptiskKofta": {
              "name": "Kofta Egiziana",
              "description": "Carne macinata alla griglia con alto contenuto di grassi, pomodoro, erbe fresche e spezie, a forma di barra, impanata con burro, grigliata fino a diventare croccante all'esterno e succosa e ricca all'interno."
            }
          }
          ,
        desserts: {
          name: "Dolci",
          egyptianCactusFruit: {
            name: "Frutto del Cactus Egiziano (Sbucciato)",
            description: "Immergiti nell'esotico con il nostro frutto del cactus egiziano sbucciato, che offre una dolcezza rinfrescante."
          },
          zalabya: {
            name: "Zalabya (Palline di Pasta Egiziane Fritte)",
            description: "Queste leggere e ariose palline dolci sono immerse nello sciroppo, quindi cospargiate di zucchero o irrorate di miele, creando una perfetta combinazione di dolcezza e consistenza."
          },
          konafaBelKeshta: {
            name: "Konafa Bel Keshta",
            description: "Vivi il dessert definitivo con la nostra Konafa Bel Keshta, che presenta un ripieno liscio e cremoso avvolto in croccanti e dorati noodle di kunafa."
          }
        },
        appetizersAndSides: {
          name: "Antipasti e Contorni",
          bazinjanPickled: {
            name: "Bazinjan Pickled",
            description: "Un delizioso piatto di melanzane sottaceto, condito con erbe e spezie per un sapore fresco."
          },
          salad: {
            name: "Insalata",
            description: "Una fresca e croccante miscela di verdure di stagione, condite con una leggera vinaigrette."
          },
          tahini: {
            name: "Tahini",
            description: "Una pasta liscia e cremosa fatta con semi di sesamo macinati, spesso usata come salsa o condimento."
          },
          babaGanoush: {
            name: "Baba Ganoush",
            description: "Una salsa di melanzane affumicate, miscelata con tahini, aglio, succo di limone e olio d'oliva per un antipasto ricco e saporito."
          },
          friedPepper: {
            name: "Peperone Fritto",
            description: "Peperoni croccanti fritti, conditi con spezie per un tocco di sapore."
          },
          friedAubergine: {
            name: "Melanzane Fritte",
            description: "Fette di melanzane leggermente impanate e fritte fino a doratura e croccantezza."
          },
          friedHotPepper: {
            name: "Peperoncino Piccante Fritto",
            description: "Peperoncini piccanti fritti alla perfezione per un antipasto dal sapore intenso."
          },
          friedPotatoLargeCuts: {
            name: "Patatine Fritte Taglio Grande",
            description: "Grandi tagli di patate, fritte fino a diventare croccanti e dorati, servite con una selezione di salse."
          },
          mixedFries: {
            name: "Patatine Fritte Miste",
            description: "Una deliziosa combinazione di patatine fritte, melanzane e peperoni, servita con la vostra scelta di salsa."
          }
        },
        beverages: {
          name: "Bevande",
          sodas: {
            name: "Bevande gassate (Cola, Fanta, ecc.)",
            description: "Una selezione di bibite classiche per soddisfare la tua sete."
          },
          karkade: {
            name: "Karkade",
            description: "Un tè rosso tradizionale egiziano fatto con fiori di ibisco, che offre un sapore aspro simile al mirtillo rosso. Questa bevanda nativa egiziana è un delizioso alternativa ai tè tradizionali."
          },
          blackTea: {
            name: "Tè nero",
            description: "Il nostro Tè Nero Celani è un omaggio alle tradizioni multietniche del tè, offrendo un'esperienza robusta e aromatica."
          }
        }
        
        
        
        }
      }
    },
    fr: {
      translation: {
        headerTitle: 'Empire Protéique Par Dr. Moe',
        getStarted: { message1: "Pour les services de traiteur ou toute autre demande, veuillez nous contacter à l'adresse suivante :" },
        nav_bar: {
          home: "Accueil",
          menu: "Menu",
          services: "Services de Traiteur",
          career: "Carrière",
          contact_us: "Nous Contacter",
        },
        hero: {
          title: "Plongez dans les Délices de la Rue Égyptienne: Où des Saveurs Savoureuses, Saines et Uniques se Promènent sur Roues",
          description1: "Plongez dans notre menu riche en options véganes, délices adaptés au régime keto et traitements carnivores savoureux, tous préparés avec une touche unique et saine.",
          description2: "Profitez du goût authentique de l'Égypte, méticuleusement élaboré pour répondre à vos besoins diététiques et titiller vos papilles, maintenant commodément sur roues.",
          cta: "Commencer",
        },
        menuCard: {
          dietTags: {
            vegan: "Végétalien",
            glutenFree: "Sans gluten",
            lowCarb: "Faible en glucides",
            keto: "Keto",
            lactoseFree: "Sans lactose",
            pescatarian: "Pescétarien",
            meat: "Viande",
            vegetarian: "Végétarien",
            veganOptionAvailable: "Option végétalienne disponible"
          },
          breads: {
            Bread: "Pain",
            baladiBruntBrod: {
              name: "Pain Brun Baladi (4 pcs)",
              description: "Un mélange copieux de son, de farine de maïs entier et de farine de blé, parfait pour tremper et les sandwiches, fournissant beaucoup de fibres."
            },
            baladiHvittBrod: {
              name: "Pain Blanc Baladi (4 pcs)",
              description: "Une option plus légère et aérée pour ceux qui préfèrent une base plus traditionnelle pour leurs repas."
            },
            ketoBrod: {
              name: "Pain Keto",
              description: "Pain faible en glucides et riche en graisses qui ne fait aucun compromis sur la texture ou le goût, parfait pour ceux qui suivent un régime faible en glucides ou cétogène; il est également naturellement sans gluten."
            }
          },
          "mainCourses": {
            "Name": "Plats Principaux",
            "fulMedames": {
              "name": "Ful Medames",
              "description": "Un incontournable de la street food égyptienne, des fèves cuites lentement assaisonnées de tahini, d'épices, de citron et d'huile d'olive."
            },
            "makaronaBashamilMincedMeat": {
              "name": "Makarona Bashamil (viande hachée)",
              "description": "Un gratin réconfortant de pâtes avec une sauce béchamel riche et crémeuse caramélisée sur le dessus au four, disponible en deux versions : viande hachée, ou une variante de fruits de mer avec des crevettes, du chou-fleur, des brocolis et de la farine de riz pour une alternative pescétarienne, sans gluten et sans lactose."
            },
            "makaronaBashamilShrimp": {
              "name": "Makarona Bashamil (crevettes)",
              "description": "Un gratin réconfortant de pâtes avec une sauce béchamel riche et crémeuse caramélisée sur le dessus au four, disponible en deux versions : viande hachée, ou une variante de fruits de mer avec des crevettes, du chou-fleur, des brocolis et de la farine de riz pour une alternative pescétarienne, sans gluten et sans lactose."
            },
            "hawawshi": {
              "name": "Hawawshi",
              "description": "Viande hachée épicée fourrée dans du pain baladi égyptien, grillée à la perfection."
            },
            "fattaOriginal": {
              "name": "Fatta Originale",
              "description": "Un plat festif avec du riz, du pain frit croustillant et de la viande superposée et recouvert d'une sauce à l'ail et au vinaigre et de bouillon, disponible en versions carnivore et végétarienne où la viande est remplacée par des aubergines."
            },
            "fattaVegan": {
              "name": "Fatta Végétarienne",
              "description": "Un plat festif avec du riz, du pain frit croustillant et de la viande superposée et recouvert d'une sauce à l'ail et au vinaigre et de bouillon, disponible en versions carnivore et végétarienne où la viande est remplacée par des aubergines."
            },
            "fatayer": {
              "name": "Fatayer",
              "description": "Délicieuses tourtes égyptiennes garnies d'un mélange de légumes ou de viande assaisonnée, cuites à la perfection dorée."
            },
            "koshary": {
              "name": "Koshary",
              "description": "Un délicieux mélange de lentilles, de riz, de pâtes et d'une sauce tomate épicée, garni d'oignons croustillants. Un plat végétalien apprécié aussi nutritif que délicieux."
            },
            "tamya": {
              "name": "Tamya",
              "description": "Contrairement au falafel, qui est à 99% de pois chiches, la tamya est faite de fèves, pétries et centrifugées jusqu'à presque former une masse liquide, résultant en une extérieur croustillant et un intérieur moelleux et léger."
            },
            "sogokAlexandria": {
              "name": "Sogok Alexandrie",
              "description": "Viande hachée épicée dans des boyaux d'agneau, proposée en deux variations régionales, chacune avec son mélange unique d'épices et de techniques culinaires. La version du Caire est préparée avec des oignons caramélisés et de petits cubes de pommes de terre (Yakhni) ; la version d'Alexandrie a un mélange différent de légumes et d'épices."
            },
            "sogokKairo": {
              "name": "Sogok Caire",
              "description": "Viande hachée épicée dans des boyaux d'agneau, proposée en deux variations régionales, chacune avec son mélange unique d'épices et de techniques culinaires. La version du Caire est préparée avec des oignons caramélisés et de petits cubes de pommes de terre (Yakhni) ; la version d'Alexandrie a un mélange différent de légumes et d'épices."
            },
            "alexandrianKebda": {
              "name": "Foie d'Alexandrie",
              "description": "Foie épicé tendre cuit à la perfection, une délicieuse friandise pour les amateurs d'abats."
            },
            "alexandrianPulledSteakMix": {
              "name": "Mélange de Steak Effiloché d'Alexandrie",
              "description": "Fines tranches de bœuf, grillées avec un mélange d'épices et de légumes et coupées en petits morceaux, servant de garniture savoureuse et réconfortante pour les sandwichs."
            },
            "stektOgGrilletBoltiFisk": {
              "name": "Tilapia du Nil Frit et Grillé",
              "description": "Assaisonné et cuit à la perfection, offrant un goût de la richesse du Nil."
            },
            "stektOgGrilletHummer": {
              "name": "Homard Frit et Grillé",
              "description": "Luxueux et savoureux, préparé avec des épices qui complètent sa douceur."
            },
            "egyptiskKofta": {
              "name": "Kofta Égyptienne",
              "description": "Viande hachée grillée avec un contenu élevé en matières grasses, tomate, herbes fraîches et épices, en forme de barre, beurrée, grillée pour être croustillante à l'extérieur et juteuse et riche à l'intérieur."
            }
          },
          desserts: {
            name: "Desserts",
            egyptianCactusFruit: {
              name: "Fruit du Cactus Égyptien (Pelé)",
              description: "Plongez dans l'exotisme avec notre fruit de cactus égyptien pelé, offrant une douceur rafraîchissante."
            },
            zalabya: {
              name: "Zalabya (Boulettes de Pâte Égyptiennes Frites)",
              description: "Ces boules sucrées légères et aériennes sont trempées dans du sirop, puis saupoudrées de sucre ou arrosées de miel, créant une parfaite combinaison de douceur et de texture."
            },
            konafaBelKeshta: {
              name: "Konafa Bel Keshta",
              description: "Découvrez le dessert ultime avec notre Konafa Bel Keshta, qui présente une garniture lisse et crémeuse enveloppée dans des nouilles de kunafa croustillantes et dorées."
            }
          },
          appetizersAndSides: {
            name: "Entrées et Accompagnements",
            bazinjanPickled: {
              name: "Bazinjan Pickled",
              description: "Un délicieux plat d'aubergines marinées, assaisonné avec des herbes et des épices pour une saveur acidulée."
            },
            salad: {
              name: "Salade",
              description: "Un mélange frais et croquant de légumes de saison, assaisonné d'une vinaigrette légère."
            },
            tahini: {
              name: "Tahini",
              description: "Une pâte lisse et crémeuse faite à partir de graines de sésame moulues, souvent utilisée comme trempette ou sauce."
            },
            babaGanoush: {
              name: "Baba Ganoush",
              description: "Un trempage d'aubergines fumées, mélangé avec du tahini, de l'ail, du jus de citron et de l'huile d'olive pour une entrée riche et savoureuse."
            },
            friedPepper: {
              name: "Poivron Frit",
              description: "Des poivrons croustillants frits, assaisonnés avec des épices pour une saveur relevée."
            },
            friedAubergine: {
              name: "Aubergine Frite",
              description: "Tranches d'aubergine légèrement panées et frites jusqu'à ce qu'elles soient dorées et croustillantes."
            },
            friedHotPepper: {
              name: "Piment Fort Frit",
              description: "Des piments forts frits à la perfection pour une entrée épicée."
            },
            friedPotatoLargeCuts: {
              name: "Frites en Gros Morceaux",
              description: "De gros morceaux de pomme de terre, frits jusqu'à ce qu'ils soient croustillants et dorés, servis avec un choix de sauces."
            },
            mixedFries: {
              name: "Frites Mélangées",
              description: "Une délicieuse combinaison de pommes de terre frites, d'aubergines et de poivrons, servie avec votre choix de sauce."
            }
          },
          beverages: {
            name: "Boissons",
            sodas: {
              name: "Sodas (Cola, Fanta, etc.)",
              description: "Une sélection de sodas classiques pour étancher votre soif."
            },
            karkade: {
              name: "Karkade",
              description: "Un thé rouge traditionnel égyptien à base de fleurs d'hibiscus, offrant une saveur acidulée rappelant celle de la canneberge. Cette boisson égyptienne indigène est une délicieuse alternative aux thés traditionnels."
            },
            blackTea: {
              name: "Thé noir",
              description: "Notre Thé Noir Celani rend hommage aux traditions multiculturelles du thé, offrant une expérience robuste et aromatique."
            }
          }
          
                    
        },

      }
    },ja: {
        translation: {
          headerTitle: 'モエ博士のプロテイン帝国',
          getStarted: { message1: 'ケータリングサービス、またはその他のお問い合わせは、以下までお問い合わせください：' },
          nav_bar: {
            home: "ホーム",
            menu: "メニュー",
            services: "ケータリングサービス",
            career: "キャリア",
            contact_us: "お問い合わせ",
          },
          hero: {
            title: "エジプトのストリートデライトを堪能：おいしい、健康的でユニークな味が車輪上で繰り広げられる場所",
            description1: "豊富なヴィーガンオプション、ケトフレンドリーなデライト、およびユニークな、健康的なアプローチで調理された美味しい肉料理のメニューに飛び込んでください。",
            description2: "あなたの食事のニーズを満たし、あなたの味覚を刺激するために丹念に作られた、エジプトの正真正銘の味を、今、便利に車輪の上でお楽しみください。",
            cta: "開始する",
          },
          menuCard:{
            dietTags: {
              vegan: "ヴィーガン",
              glutenFree: "グルテンフリー",
              lowCarb: "低炭水化物",
              keto: "ケト",
              lactoseFree: "乳糖フリー",
              pescatarian: "ペスカタリアン",
              meat: "肉",
              vegetarian: "ベジタリアン",
              veganOptionAvailable: "ヴィーガンオプションあり"
            },
            breads: {
              Bread: "パン",
              baladiBruntBrod: {
                name: "バラディブラウンブレッド (4個)",
                description: "ふすま、全粒コーンフラワー、小麦粉のハーティなブレンド。ディッピングやサンドイッチに最適で、豊富な繊維を提供します。"
              },
              baladiHvittBrod: {
                name: "バラディホワイトブレッド (4個)",
                description: "より伝統的なベースを好む人のための、より軽くて空気のようなオプション。"
              },
              ketoBrod: {
                name: "ケトブレッド",
                description: "低炭水化物、高脂肪のパンで、質感や味を損なうことなく、低炭水化物またはケトジェニックダイエットをしている人に最適。自然にグルテンフリーです。"
              }
            },
            "mainCourses": {
              "Name": "メイン料理",
              "fulMedames": {
                "name": "フール・メダムス",
                "description": "エジプトのストリートフードの定番で、テヒニ、スパイス、レモン、オリーブオイルで味付けしたスローコックしたファバ豆。"
              },
              "makaronaBashamilMincedMeat": {
                "name": "マカロナ・バシャミル（ひき肉）",
                "description": "オーブンで上部が焦げたリッチでクリーミーなベシャメルソースがかかったコンフォーティングなパスタのベイク。ひき肉、またはエビ、カリフラワー、ブロッコリー、米粉を使用したシーフードバリアントの2つのバージョンで利用可能です。"
              },
              "makaronaBashamilShrimp": {
                "name": "マカロナ・バシャミル（エビ）",
                "description": "オーブンで上部が焦げたリッチでクリーミーなベシャメルソースがかかったコンフォーティングなパスタのベイク。ひき肉、またはエビ、カリフラワー、ブロッコリー、米粉を使用したシーフードバリアントの2つのバージョンで利用可能です。"
              },
              "hawawshi": {
                "name": "ハワウィシ",
                "description": "エジプトのバラディブレッドに詰めたスパイシーなひき肉、完璧に焼き上げられた。"
              },
              "fattaOriginal": {
                "name": "ファッタ・オリジナル",
                "description": "米、カリカリの揚げパン、肉を重ねてガーリック酢ソースとブイヨンをかけた祝祭料理。肉はナスに置き換えられる肉食と菜食主義のバージョンで利用可能です。"
              },
              "fattaVegan": {
                "name": "ファッタ・ヴィーガン",
                "description": "米、カリカリの揚げパン、肉を重ねてガーリック酢ソースとブイヨンをかけた祝祭料理。肉はナスに置き換えられる肉食と菜食主義のバージョンで利用可能です。"
              },
              "fatayer": {
                "name": "ファタイヤ",
                "description": "選べる野菜のミックスまたは味付けされた肉で満たされたエジプトの風味豊かなパイ、黄金色に焼き上げた。"
              },
              "koshary": {
                "name": "コシャリ",
                "description": "レンズ豆、米、パスタ、スパイシートマトソースの美味しい混合物、カリカリの玉ねぎをトッピングした。栄養価が高く美味しいベジタリアン料理。"
              },
              "tamya": {
                "name": "タミヤ",
                "description": "99%がひよこ豆のフラフェルとは異なり、タミヤはふすま豆から作られ、ほぼ液体状に練り、外はカリカリで中はふんわりとしています。"
              },
              "sogokAlexandria": {
                "name": "ソゴック・アレクサンドリア",
                "description": "羊の腸に入れられたスパイシーなひき肉、2つの地域のバリエーションで提供され、それぞれが独自のスパイスと調理技術のブレンドを持っています。カイロバージョンは、キャラメル化した玉ねぎと小さなポテトキューブ（ヤクニ）で作られています。"
              },
              "sogokKairo": {
                "name": "ソゴック・カイロ",
                "description": "羊の腸に入れられたスパイシーなひき肉、2つの地域のバリエーションで提供され、それぞれが独自のスパイスと調理技術のブレンドを持っています。アレクサンドリアバージョンは、異なる野菜とスパイスのブレンドを持っています。"
              },
              "alexandrianKebda": {
                "name": "アレクサンドリアのケブダ",
                "description": "完璧に調理されたスパイシーな肝臓、内臓の愛好家のための美味しいおやつ。"
              },
              "alexandrianPulledSteakMix": {
                "name": "アレクサンドリアのプルドステーキミックス",
                "description": "スパイスと野菜のミックスでグリルされた細切りの牛肉、サンドイッチのための風味豊かで温かい具として使用されています。"
              },
              "stektOgGrilletBoltiFisk": {
                "name": "ナイルティラピアのフライ＆グリル",
                "description": "完璧に調理されたニロの宝物の味を提供。"
              },
              "stektOgGrilletHummer": {
                "name": "フライ＆グリルロブスター",
                "description": "豪華で風味豊かな、甘みを引き立てるスパイスで調理。"
              },
              "egyptiskKofta": {
                "name": "エジプト風コフタ",
                "description": "豊富な脂肪分を含むひき肉、トマト、新鮮なハーブ、スパイス、バタープレート、カリカリの外側とジューシーで豊かな内側にグリルされた棒状。"
              }
            }      ,
            desserts: {
              name: "デザート",
              egyptianCactusFruit: {
                name: "エジプトのサボテンの実（皮をむいたもの）",
                description: "さわやかな甘いおやつを提供する、エキゾチックなエジプトのサボテンの実（皮をむいたもの）に没頭してください。"
              },
              zalabya: {
                name: "ザラビア（エジプトの揚げたてパイの玉）",
                description: "これらの軽くてふんわりした甘いボールは、糖漬けのシロップに浸され、砂糖を振りかけたり、ハチミツを垂らしたりして、甘さと食感の完璧なブレンドを作り出します。"
              },
              konafaBelKeshta: {
                name: "コナファ・ベル・ケシュタ",
                description: "サクサクした黄金色のクナファ麺で包まれた、滑らかでクリーミーなフィリングをお楽しみいただける究極のデザートを体験してください。"
              }
            },
            appetizersAndSides: {
              name: "前菜とサイド",
              bazinjanPickled: {
                name: "Bazinjan Pickled",
                description: "香草とスパイスで調味された美味しいピクルドなナスの料理。さっぱりとした味わいです。"
              },
              salad: {
                name: "サラダ",
                description: "季節の野菜を使ったさわやかなミックスサラダ。軽いビネグレットドレッシングで仕上げました。"
              },
              tahini: {
                name: "タヒニ",
                description: "すりおろしたゴマから作られたなめらかでクリーミーなペースト。ディップやソースとしてよく使われます。"
              },
              babaGanoush: {
                name: "ババガヌーシュ",
                description: "スモークしたナスのディップ。タヒニ、ニンニク、レモンジュース、オリーブオイルと混ぜて、リッチで風味豊かな前菜に。"
              },
              friedPepper: {
                name: "フライドペッパー",
                description: "スパイスを効かせた、カリカリのフライドペッパー。味わい豊かな一品です。"
              },
              friedAubergine: {
                name: "フライドエッグプラント",
                description: "さっくりと揚げたナスのスライス。外はカリッと、中はしっとりと仕上げました。"
              },
              friedHotPepper: {
                name: "フライドホットペッパー",
                description: "パリパリの辛いホットペッパー。パーフェクトな辛さの前菜です。"
              },
              friedPotatoLargeCuts: {
                name: "フライドポテト大きな切り口",
                description: "カリカリに揚げた大きなポテトの切り口。お好みのソースと一緒にお召し上がりください。"
              },
              mixedFries: {
                name: "ミックスフライ",
                description: "フライドポテト、ナス、ピーマンの美味しい組み合わせ。お好みのソースと一緒にお召し上がりください。"
              }
            },
            beverages: {
              name: "飲み物",
              sodas: {
                name: "炭酸飲料（コーラ、ファンタ、など）",
                description: "のどを潤すためのクラシックな炭酸飲料の選り抜き。"
              },
              karkade: {
                name: "カルカデ",
                description: "ハイビスカスの花から作られた伝統的なエジプトの紅茶で、クランベリーのようなほろ酸っぱい味が特徴です。このエジプト固有の飲み物は、伝統的なお茶の素晴らしい代替品です。"
              },
              blackTea: {
                name: "紅茶",
                description: "当店のセラニ・ブラックティーは、多文化なお茶の伝統へのオマージュで、力強く芳醇な体験を提供します。"
              }
            }
            
                  
          },

          
        }
      },
      zh: {
        translation: {
          headerTitle: '摩博士的蛋白帝国',
          getStarted: { message1: '对于餐饮服务或任何其他查询，请联系我们以下方式：' },
          nav_bar: {
            home: "首页",
            menu: "菜单",
            services: "餐饮服务",
            career: "事业",
            contact_us: "联系我们",
          },
          hero: {
            title: "沉浸在埃及街头的美味中：美味、健康和独特的味道在轮子上漫步",
            description1: "深入了解我们丰富的素食选择、适合生酮饮食的美味佳肴和美味的肉食美食菜单，所有菜品都经过独特、健康的调理。",
            description2: "享受埃及地道的味道，精心制作以满足您的饮食需求，并使您的味蕾得到刺激，现在方便地放在轮子上。",
            cta: "开始",
          },
          menuCard: {
            dietTags: {
              vegan: "素食",
              glutenFree: "无麸质",
              lowCarb: "低碳水",
              keto: "生酮",
              lactoseFree: "无乳糖",
              pescatarian: "鱼素",
              meat: "荤食",
              vegetarian: "素食",
              veganOptionAvailable: "提供素食选项"
            },
            breads: {
              Bread: "面包",
              baladiBruntBrod: {
                name: "巴拉迪全麦面包（4片）",
                description: "一种包含麸皮、全粒玉米面和小麦面的丰富混合物，非常适合蘸食和三明治，提供丰富的纤维。"
              },
              baladiHvittBrod: {
                name: "巴拉迪白面包（4片）",
                description: "对于那些更喜欢传统基底的人来说，这是一个更轻、更空气感的选择。"
              },
              ketoBrod: {
                name: "生酮面包",
                description: "低碳水化合物、高脂肪的面包，既不妥协质地也不妥协味道，非常适合那些跟随低碳或生酮饮食的人；它也是天然无麸质的。"
              }
            },
              "mainCourses": {
                "Name": "主菜",
                "fulMedames": {
                  "name": "富尔梅达姆斯",
                  "description": "埃及街头食品的主打菜，用芝麻酱、香料、柠檬和橄榄油慢炖的蚕豆。"
                },
                "makaronaBashamilMincedMeat": {
                  "name": "马卡罗纳·巴沙米尔（碎肉）",
                  "description": "在烤箱中烘烤至顶部糊化的浓郁奶油白酱的安慰性意大利面焗，有两种版本可供选择：碎肉或海鲜变种，其中包括虾、花椰菜、西兰花和大米粉，适合素食主义者、无麸质和无乳制品的替代品。"
                },
                "makaronaBashamilShrimp": {
                  "name": "马卡罗纳·巴沙米尔（虾）",
                  "description": "在烤箱中烘烤至顶部糊化的浓郁奶油白酱的安慰性意大利面焗，有两种版本可供选择：碎肉或海鲜变种，其中包括虾、花椰菜、西兰花和大米粉，适合素食主义者、无麸质和无乳制品的替代品。"
                },
                "hawawshi": {
                  "name": "哈瓦什",
                  "description": "在埃及巴拉迪面包中填入的辛辣碎肉，烤至脆脆的完美状态。"
                },
                "fattaOriginal": {
                  "name": "法塔原味",
                  "description": "米饭、炸脆面包和肉层叠，并涂上大蒜醋汁和清汤，有肉食和素食主义者版本可供选择，肉被茄子替代。"
                },
                "fattaVegan": {
                  "name": "法塔素食",
                  "description": "米饭、炸脆面包和肉层叠，并涂上大蒜醋汁和清汤，有肉食和素食主义者版本可供选择，肉被茄子替代。"
                },
                "fatayer": {
                  "name": "法泰耶",
                  "description": "填充了蔬菜混合物或调味肉的美味埃及馅饼，烤至金黄色。"
                },
                "koshary": {
                  "name": "科沙利",
                  "description": "一道美味的扁豆、米饭、意大利面和辣番茄酱的混合，上面撒有脆洋葱。一道深受欢迎的素食菜肴，既美味又有营养。"
                },
                "tamya": {
                  "name": "塔米亚",
                  "description": "与99%的鹰嘴豆沙拉不同，塔米亚是由蚕豆制成的，揉搓并离心至几乎液体状，结果是外皮酥脆，内部松软/蓬松。"
                },
                "sogokAlexandria": {
                  "name": "亚历山大炒肉肠",
                  "description": "羊肉肉馅装入羊肠，提供两种地区变体，每种都有独特的香料和烹饪技术。开罗版是用焦糖化的洋葱和微小的土豆立方体（Yakhni）制作的；亚历山大版本具有不同的蔬菜和香料混合物。"
                },
                "sogokKairo": {
                  "name": "开罗炒肉肠",
                  "description": "羊肉肉馅装入羊肠，提供两种地区变体，每种都有独特的香料和烹饪技术。开罗版是用焦糖化的洋葱和微小的土豆立方体（Yakhni）制作的；亚历山大版本具有不同的蔬菜和香料混合物。"
                },
                "alexandrianKebda": {
                  "name": "亚历山大肝",
                  "description": "嫩嫩的香料肝脏烹饪至完美，是内脏爱好者的美味佳肴。"
                },
                "alexandrianPulledSteakMix": {
                  "name": "亚历山大火腿拔丝混合",
                  "description": "细切牛肉，用一种混合了香料和蔬菜的混合物烤制，剪成小块，用作香味浓郁的、温暖的三明治馅料。"
                },
                "stektOgGrilletBoltiFisk": {
                  "name": "煎烤尼罗罗非鱼",
                  "description": "经调味并烹饪至完美，提供了尼罗河丰富的味道。"
                },
                "stektOgGrilletHummer": {
                  "name": "煎炸烤龙虾",
                  "description": "奢华而美味，用能衬托其甜味的香料制备。"
                },
                "egyptiskKofta": {
                  "name": "埃及肉丸",
                  "description": "用丰富的脂肪含量、番茄、新鲜香草和香料制成的烤碎肉，条形，用黄油包裹，烤至外脆内嫩。"
                }
              },
              desserts: {
                name: "甜点",
                egyptianCactusFruit: {
                  name: "埃及仙人掌果实（剥皮）",
                  description: "尽情享受我们剥皮的埃及仙人掌果实，提供清爽的甜点享受。"
                },
                zalabya: {
                  name: "Zalabya（埃及炸酥面团球）",
                  description: "这些轻盈蓬松的甜球浸泡在糖浆中，可以撒上糖或淋上蜂蜜，营造出甜美和口感的完美组合。"
                },
                konafaBelKeshta: {
                  name: "Konafa Bel Keshta",
                  description: "尽情享受我们的Konafa Bel Keshta，这款甜点由脆脆的金黄色Kunafa面团包裹着柔滑丝滑的馅料。"
                }
              },
              appetizersAndSides: {
                name: "开胃菜和配菜",
                bazinjanPickled: {
                  name: "Bazinjan Pickled",
                  description: "美味的泡制茄子菜肴，用香草和香料调味，口感清爽。"
                },
                salad: {
                  name: "沙拉",
                  description: "新鲜爽脆的时令蔬菜混合，轻盈的油醋汁调味。"
                },
                tahini: {
                  name: "芝麻酱",
                  description: "由磨碎的芝麻制成的细腻而奶油的酱，通常用作蘸酱或调味酱。"
                },
                babaGanoush: {
                  name: "巴巴甘酱",
                  description: "烟熏茄子酱，与芝麻酱、大蒜、柠檬汁和橄榄油混合，口感丰富，味道浓郁。"
                },
                friedPepper: {
                  name: "炸辣椒",
                  description: "香料调味的香脆辣椒，口感十足。"
                },
                friedAubergine: {
                  name: "炸茄子",
                  description: "茄子片轻轻裹上面粉，炸至金黄酥脆。"
                },
                friedHotPepper: {
                  name: "炸辣椒",
                  description: "香辣的辣椒油炸至完美，火辣开胃。"
                },
                friedPotatoLargeCuts: {
                  name: "炸土豆大块",
                  description: "大块土豆炸至金黄酥脆，可搭配多种蘸酱。"
                },
                mixedFries: {
                  name: "混合炸食",
                  description: "炸土豆、茄子和辣椒的美味组合，搭配您喜爱的蘸酱。"
                }
              },
              beverages: {
                name: "饮料",
                sodas: {
                  name: "汽水（可乐、芬达等）",
                  description: "经典汽水的选择，解渴之选。"
                },
                karkade: {
                  name: "洛神花茶",
                  description: "一种由洛神花制成的传统埃及红茶，具有酸酸的味道，类似于蔓越莓。这种本土埃及饮料是传统茶的美味替代品。"
                },
                blackTea: {
                  name: "红茶",
                  description: "我们的塞拉尼红茶是对多元文化茶文化的致敬，提供浓郁芬芳的体验。"
                }
              }
              
                    
            },        
            
        }
      },
      so: {
        translation: {
          headerTitle: 'Xeebaha Biyaha Ee Dr. Moe',
          getStarted: { message1:"Xidhiidhka Kaataaringga ama su'aallo kale, fadlan nala soo xiriir halkan:" },
          nav_bar: {
            home: "Guriga",
            menu: "Menu",
            services: "Xidhiidhka Kaataaringga",
            career: "Hawlo",
            contact_us: "Nala soo xiriir",
          },
          hero: {
            title: "Ku xir xafada Masaafooyinka Masar: Halka Masaafooyinka Foornada, Caafimaadka iyo Aan La Xirxiranayn La joogo",
            description1: "Ku biir meniyadeena ee heesta vegan-ka ah, farxadlayaal aasaasiyaad kale, iyo suuqyada aan la qabaa 'iyo noocyada qaddiyadda Foornada, oo dhammaadka la sameeyay, la jaanqaadayna xirfadlayaal.",
            description2: "Ku biir isla dhaqanka Masar, oo loo sameeyay si faahfaahsan oo loo sameeyay si looga siiyo talooyinka cuntadaada iyo si loo ku daah-furnaado dhalmadaada, hadda oo mugdiga gariirka la heli kara.",
            cta: "Bilaab",
          },
          menuCard: {
            dietTags: {
              vegan: "Vegan",
              glutenFree: "Bilaash Gluten",
              lowCarb: "Karbohaydrayt Hoose",
              keto: "Keto",
              lactoseFree: "Laktoos La'aan",
              pescatarian: "Kalluumeysi",
              meat: "Hilib",
              vegetarian: "Khudaar",
              veganOptionAvailable: "Ikhtiyaar Vegan ah ayaa la heli karaa"
            },
            breads: {
              Bread: "Rooti",
              baladiBruntBrod: {
                name: "Rootiga Baladi Brown (4 xabo)",
                description: "Isku dhafan xoog leh oo bran, daqiiqda galleyda dhan, iyo daqiiqda qamadiga, ku habboon dipping iyo sandwiches, bixinta fiber badan."
              },
              baladiHvittBrod: {
                name: "Rootiga Baladi Caddaan (4 xabo)",
                description: "Ikhtiyaar ka fudud, hawo leh kuwa jecel saldhig dhaqameed oo cuntooyinkooda ah."
              },
              ketoBrod: {
                name: "Rootiga Keto",
                description: "Rooti yar oo karbohaydrayt ah, dufan sare leh oo aan ku tanaasulin dhadhan ama qaab, ku habboon kuwa raacaya karbohaydrayt yar ama cuntada keto; sidoo kale si dabiici ah uga madhan gluten."
              }
            },
              "mainCourses": {
                "Name": "Cuntooyinka Kuuqanisan",
                "fulMedames": {
                  "name": "Ful Medames",
                  "description": "Cuntooyinka reer Masar ee wadaaga ee favahada oo loo garaaco tahini, maraq, liimo iyo oliifada."
                },
                "makaronaBashamilMincedMeat": {
                  "name": "Makarona Bashamil (hilib la dhuubo)",
                  "description": "Cajiinka biyo ah ee qasasta ee biyo leh oo loo siinayo kawaan karboonays ah oo la soo saaro daboolka ah, waxaana lagu heli karaa laba nooc oo kala duwan: hilibka la dhuubo ama mid kale oo dhexdeeda leh kalluun, misir, kalluunka iyo buruq buur kalluun oo loogu talogalay qolo qumbe, loona badbaadinayo qololoyin caadiga ah oo suuxul iyo burkadi ah."
                },
                "makaronaBashamilShrimp": {
                  "name": "Makarona Bashamil (jangeli)",
                  "description": "Cajiinka biyo ah ee qasasta ee biyo leh oo loo siinayo kawaan karboonays ah oo la soo saaro daboolka ah, waxaana lagu heli karaa laba nooc oo kala duwan: hilibka la dhuubo ama mid kale oo dhexdeeda leh kalluun, misir, kalluunka iyo buruq buur kalluun oo loogu talogalay qolo qumbe, loona badbaadinayo qololoyin caadiga ah oo suuxul iyo burkadi ah."
                },
                "hawawshi": {
                  "name": "Hawawshi",
                  "description": "Hilibka dhuubta ah ee Masar loogu tala galay cunto suqaar oo la siiriyo daawaha la cagaaray."
                },
                "fattaOriginal": {
                  "name": "Fatta Original",
                  "description": "Waa cunto caadi ah oo ku yaal riis, rooti cusbo oo la-friida, iyo hilibka lagu ilko iloobay, waxaana lagu soo saaray maraq-ebbe, maraq dabiic ah, oo leh laba nooc oo loo yaqaan: kalluunka iyo kalluunka caadiga ah oo lagu beddelay bataan."
                },
                "fattaVegan": {
                  "name": "Fatta Vegan",
                  "description": "Waa cunto caadi ah oo ku yaal riis, rooti cusbo oo la-friida, iyo hilibka lagu ilko iloobay, waxaana lagu soo saaray maraq-ebbe, maraq dabiic ah, oo leh laba nooc oo loo yaqaan: kalluunka iyo kalluunka caadiga ah oo lagu beddelay bataan."
                },
                "fatayer": {
                  "name": "Fatayer",
                  "description": "Sambuusyada Masar oo fudud oo ku jira karboon iyo hilib ama hilib dhexdiisa ah, loo cusbo bata gudaamida."
                },
                "koshary": {
                  "name": "Koshary",
                  "description": "Waa hal cunto flakka oo la yidhaahdo kaluunka, biyo, makarooni iyo mooska tamatada, oo laga cunin quruxda badanaa. Cunto vegan ah oo lama suuxdaalno."
                },
                "tamya": {
                  "name": "Tamya",
                  "description": "Kuwaasa falafel ah oo ah 99% misir, tamya waa lagu sameeyaa marka lagu daro misirka, looguna dhigaa meesha isku mid ah iyo meesha soft / fluffy ah."
                },
                "sogokAlexandria": {
                  "name": "Sogok Alexandria",
                  "description": "Hilibka dhuubta ah oo misir lagu cabiray, laakiin lagu siinayo laba nooc oo ka duwan, mid kala duwan oo leh masraaco iyo habab uga danbeeyay cagaar iyo qaababka riyaadi ee kalluunka. Noloshi Kahiriga waxaa lagu sameeyaa basas ah iyo kubbadda baruurta cas."
                },
                "sogokKairo": {
                  "name": "Sogok Kairo",
                  "description": "Hilibka dhuubta ah oo misir lagu cabiray, laakiin lagu siinayo laba nooc oo ka duwan, mid kala duwan oo leh masraaco iyo habab uga danbeeyay cagaar iyo qaababka riyaadi ee kalluunka. Noloshi Kahiriga waxaa lagu sameeyaa basas ah iyo kubbadda baruurta cas."
                },
                "alexandrianKebda": {
                  "name": "Alexandrian Kebda",
                  "description": "Hilibka dhuubta ah ee fudud, loogu tala galay jirka, waxaa lagu cusboonaysiiyay dabool kala duwan oo la-friida, kaas oo ah isku dhaf iyo quruun, isagoo ka siinaya xafiis."
                },
                "alexandrianPulledSteakMix": {
                  "name": "Alexandrian Pulled Steak Mix",
                  "description": "Hilibka cunuga ah ee basas ah, loogu tala galay masraaco kala duwan oo la-friida, kaas oo ah xalluun iyo cuntada, lagu daro qaaddo yar, looguna saaf fidiya in lagu siiyo kan lafahaaga iyo kalluunka."
                },
                "stektOgGrilletBoltiFisk": {
                  "name": "Stekt og Grillet Bolti Fisk (Nilen Tilapia)",
                  "description": "Kalaami fudud oo misir lagu marinado oo la-friida, si ay u sameeyaan cajiinaynta Abukariga."
                },
                "stektOgGrilletHummer": {
                  "name": "Stekt og Grillet Hummer",
                  "description": "Kalaami cas oo fudud, loogu tala galay hababab oo kaaga dhacaysa barakac."
                },
                "egyptiskKofta": {
                  "name": "Egyptisk Kofta",
                  "description": "Hilibka dhuubta ah oo la-friida lagu cabiray miro, tamato, maraqo cusub iyo maraqo spices, si loo helo weyn iyo nadiif dhexe."
                }
              }          ,
              desserts: {
                name: "Deshertada",
                egyptianCactusFruit: {
                  name: "Fruuktiga Kaktuuska Eebbe",
                  description: "Dhibcaha sawirka oo loo isticmaalay ee eebbe ee Kaktuuska Misri ee lagu korodhsado, sida laga soo darista inuu siiyo cunto macaan oo midabka leh."
                },
                zalabya: {
                  name: "Zalabya (Quruxda Dabka Misri oo la Riixiyo)",
                  description: "Bolladaha macaan ee nool iyo hawooyin, waxay noqon karaan soo gaarista shekar si uu u sahlanaado, ama dabarid si uu u sahlan uga digo miiska, waxayna abaaraha iyo qaadooyinka ku koobanayd xuduudaha aad ugu macaan yihiin."
                },
                konafaBelKeshta: {
                  name: "Konafa Bel Keshta",
                  description: "Daawo dessertka ugu sarreeya ee Konafa Bel Keshta ee kasoo baxday, oo ku jira bollad maqnaansho iyo cad, oo laga arki karo ciidanka kunafa ee labo gobol, ama kunafa iyada oo ku socda."
                }
              },
              appetizersAndSides: {
                name: "Qadaadka Ugu Horeeya iyo Sidaha",
                bazinjanPickled: {
                  name: "Bazinjan Pickled",
                  description: "Macmacaan cad oo lagu cuno qadooyinka, lagu qoray maraqiin iyo cunnooyin si uu ugu dhow uu kala duwan yahay."
                },
                salad: {
                  name: "Salad",
                  description: "Cajiin taza iyo banaan oo ka kooban khudradada fasalka, la soo saaray sallad caloosha iyo biinadka."
                },
                tahini: {
                  name: "Tahini",
                  description: "Macmacaan lahayn oo cad, laga sameeyo habka lagu dhufto geela shanuud, markasta oo loo isticmaalayo maraq ama laabo."
                },
                babaGanoush: {
                  name: "Baba Ganoush",
                  description: "Macmacaan qadooyinka oo la cunaqabooyaa, isla markaana la saarayo tahini, toon, lacag daba socda iyo shamu kaniisnada ee oliifada."
                },
                friedPepper: {
                  name: "Filfil loo Furaayo",
                  description: "Filfilka loo furaayo ee loo soo celiyo maraqiin si uu ugu dhow uu khudradka cunista ka saaro."
                },
                friedAubergine: {
                  name: "Badaladay Hilibka",
                  description: "Badaladay shuudhuufan, marka laga qaybiyo cajiin iyo looyaqaano aan buuxin, waxaa lagu dhuftay dhago cajiin."
                },
                friedHotPepper: {
                  name: "Filfilka loo Furaayo",
                  description: "Filfilka suulka loo furaayo ilaa ay dhameystaan, laguna xaddido khibrad loo soo saaro."
                },
                friedPotatoLargeCuts: {
                  name: "Bataato Waaweyn",
                  description: "Bataatoyga waaweyn oo loo dhufto ilaa ay dhameystaan, lagu ciyaaro xawaashii furka aad rabtid."
                },
                mixedFries: {
                  name: "Miis Furaayo",
                  description: "Isku dhafan oo shuudhuufan, badaan iyo filfilka loo furaayo, oo laga sameeyo xawaashii aad isticmaasho."
                }
              } ,
              beverages: {
                name: "Cabitaano",
                sodas: {
                  name: "Soda (Cola, Fanta, iwm.)",
                  description: "Dooggaaga ku qabso, oo iskuulaynaysa si aad uga raadiyo cadceeda."
                },
                karkade: {
                  name: "Karkade",
                  description: "Shay cajbeed oo reer Masar ah oo lagu sameeyo dhagooyinka xibiskuska, oo kaaga baxaya cajiinka. Cabitaanka reer Masar eeasas eeasas ah waa ka mid ah kuwo adag ee loo yaqaan cajada caadiga ah."
                },
                blackTea: {
                  name: "Shay madow",
                  description: "Shayga Madowka ee Celani waa tacadiyad ka dhigidda dhaqanka shaahda kala duwan, oo ku bixiya halxidhaale aad u dhow."
                }
              }
                   
              
            
          }
        }
      },

      
  };
// Retrieve saved language or use browser's default
const savedLanguage = localStorage.getItem('language');
const browserLanguage = navigator.language.split('-')[0];
const defaultLanguage = savedLanguage || browserLanguage || 'en'; // Fallback to 'en'

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: defaultLanguage,
    fallbackLng: "en",
    interpolation: { escapeValue: false },
  });


export default i18n;
