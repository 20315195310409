import React from 'react'

import { useTranslation } from 'react-i18next';
export default function GetStarted() {
        
    const { t } = useTranslation();
    return (
        <div>
            <section id="contact" className="contact">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{t('nav_bar.contact_us')}</h2>
                    </div>
                    <div className="row content" style={{ "text-align": "center;"}}>
                        <div className="col-lg-12">
                            <ul>
                                <li>
                                    {t('getStarted.message1')}  </li>                                

                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i className="bi bi-geo-alt" />
                                    <h4>HQ:</h4>
                                    <p>Eilert Sundts Gate 11A, 0259 Oslo</p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" />
                                    <h4>Email:</h4>
                                    <p>mo.ibrahimsen@nohaibrahimconsultancy.com</p>
                                </div>
                                <div className="phone">
                                    <i className="bi bi-phone" />
                                    <h4>Call:</h4>
                                    <p>48240032</p>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3999.355231077714!2d10.71346567731994!3d59.92089756352874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416dd157407f6d%3A0xb0018caee205733d!2sEilert%20Sundts%20gate%2011A%2C%200259%20Oslo!5e0!3m2!1sen!2sno!4v1711964825291!5m2!1sen!2sno" frameBorder={0} style={{ border: 0, width: '100%', height: 290 }} allowFullScreen />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
