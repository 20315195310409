import React from 'react';

import { useTranslation } from 'react-i18next';
// Assuming ItemComponent is where you'll define how each menu item is displayed
import ItemComponent from './ItemComponent';

export default function MenuCard() {
  const getDietTags = (tags) => {
    return tags.split(', ').map(tag => t(`menuCard.dietTags.${tag}`)).join(', ');
  };
  const { t } = useTranslation();
  const groupedMenuItems = {
    Breads: [
        {
            name: t('menuCard.breads.baladiBruntBrod.name'),
            description: t('menuCard.breads.baladiBruntBrod.description'),
            imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/baladi-brunt-brod.png`,
            prices: { mini: '20kr', small: '35kr', mid: '45kr', large: '50kr' },
            dietTag: getDietTags('vegan')
        },
        {
            name: t('menuCard.breads.baladiHvittBrod.name'),
            description: t('menuCard.breads.baladiHvittBrod.description'),
            imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/baladi-hvitt-brod.png`,
            prices: { mini: '20kr', small: '35kr', mid: '45kr', large: '50kr' },
            dietTag:getDietTags('vegan')
        },
        {
            name: t('menuCard.breads.ketoBrod.name'),
            description: t('menuCard.breads.ketoBrod.description'),
            imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/keto-brod.png`,
            prices: { oneSlice: '25kr', twoSlice: '40kr', threeSlice: '50kr' },
            dietTag: getDietTags('glutenFree, lowCarb, keto')
        }
    ],
    mainCourses : [
      {
        name: t('menuCard.mainCourses.fulMedames.name'),
        description: t('menuCard.mainCourses.fulMedames.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fool.png`,
        prices: { mini: '25kr', small: '50kr', mid: '75kr', large: '100kr', extraLarge: '125kr', telarkenr: '150kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.mainCourses.makaronaBashamilMincedMeat.name'),
        description: t('menuCard.mainCourses.makaronaBashamilMincedMeat.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/makarona-bashamil.png`,
        prices: { mini: '35kr', small: '60kr', mid: '85kr', large: '110kr', extraLarge: '135kr', telarkenr: '160kr' },
        dietTag: '' // No specific diet tag for this variant
      },
      {
        name: t('menuCard.mainCourses.makaronaBashamilShrimp.name'),
        description: t('menuCard.mainCourses.makaronaBashamilShrimp.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/makarona-bashamil.png`,
        prices: { mini: '35kr', small: '60kr', mid: '85kr', large: '110kr', extraLarge: '135kr', telarkenr: '160kr' },
        dietTag: getDietTags('lactoseFree, pescatarian, glutenFree')
      },
      {
        name: t('menuCard.mainCourses.hawawshi.name'),
        description: t('menuCard.mainCourses.hawawshi.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/hawashi.png`,
        prices: { mini: '40kr', mid: '100kr', large: '125kr', telarkenr: '165kr' },
        dietTag: '' // No specific diet tag for this item
      },
      {
        name: t('menuCard.mainCourses.fattaOriginal.name'),
        description: t('menuCard.mainCourses.fattaOriginal.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fatta.png`,
        prices: { small: '70kr', mid: '110kr', large: '150kr', telarkenr: '180kr' },
        dietTag: getDietTags('meat')
      },
      {
        name: t('menuCard.mainCourses.fattaVegan.name'),
        description: t('menuCard.mainCourses.fattaVegan.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fattavegan.png`,
        prices: { small: '70kr', mid: '95kr', large: '120kr', telarkenr: '160kr' },
        dietTag: getDietTags('vegetarian')
      },
      {
        name: t('menuCard.mainCourses.fatayer.name'),
        description: t('menuCard.mainCourses.fatayer.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fatayer.png`,
        prices: { roll: '50kr', twoPI: '100kr', telarkenr: '130kr' },
        dietTag: getDietTags('veganOptionAvailable')
      },
      {
        name: t('menuCard.mainCourses.koshary.name'),
        description: t('menuCard.mainCourses.koshary.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/koshri.png`,
        prices: { mini: '55kr', small: '80kr', mid: '105kr', large: '130kr', extraLarge: '155kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.mainCourses.tamya.name'),
        description: t('menuCard.mainCourses.tamya.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/tamya.png`,
        prices: { mini: '30kr', small: '55kr', mid: '80kr', large: '105kr', extraLarge: '130kr', telarkenr: '155kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.mainCourses.sogokAlexandria.name'),
        description: t('menuCard.mainCourses.sogokAlexandria.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/sogokAlex.png`,
        prices: { mini: '60kr', small: '85kr', mid: '110kr', large: '135kr', extraLarge: '160kr', telarkenr: '185kr' },
        dietTag: '' // No specific diet tag for this item
      },
      {
        name: t('menuCard.mainCourses.sogokKairo.name'),
        description: t('menuCard.mainCourses.sogokKairo.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/sogok.png`,
        prices: { mini: '60kr', small: '85kr', mid: '110kr', large: '135kr', extraLarge: '160kr', telarkenr: '185kr' },
        dietTag: '' // No specific diet tag for this item
      },
      {
        name: t('menuCard.mainCourses.alexandrianKebda.name'),
        description: t('menuCard.mainCourses.alexandrianKebda.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/kebda.png`,
        prices: { mini: '40kr', small: '60kr', mid: '80kr', large: '100kr', extraLarge: '120kr', telarkenr: '150kr' },
        dietTag: '' // No specific diet tag for this item
      },
      {
        name: t('menuCard.mainCourses.alexandrianPulledSteakMix.name'),
        description: t('menuCard.mainCourses.alexandrianPulledSteakMix.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/alexandrian-pulled-steak-mix.png`,
        prices: { mini: '70kr', small: '95kr', mid: '120kr', large: '145kr', extraLarge: '170kr', telarkenr: '195kr' },
        dietTag: '' // No specific diet tag for this item
      },
      {
        name: t('menuCard.mainCourses.stektOgGrilletBoltiFisk.name'),
        description: t('menuCard.mainCourses.stektOgGrilletBoltiFisk.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/bolti-fisk.png`,
        prices: { One: '120kr', Two: '190kr', Three: '240kr'},
        dietTag: getDietTags('pescatarian')
      },
      {
        name: t('menuCard.mainCourses.stektOgGrilletHummer.name'),
        description: t('menuCard.mainCourses.stektOgGrilletHummer.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/grillet-hummer.png`,
        prices: { telarkenr: '205kr' },
        dietTag: getDietTags('pescatarian')
      },
      {
        name: t('menuCard.mainCourses.egyptiskKofta.name'),
        description: t('menuCard.mainCourses.egyptiskKofta.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/egyptisk-kofta.png`,
        prices: { mini: '55kr', small: '80kr', mid: '115kr', large: '130kr', extraLarge: '155kr', telarkenr: '210kr' },
        dietTag: '' // No specific diet tag for this item
      }
    ],
    Desserts: [
      {
        name: t('menuCard.desserts.egyptianCactusFruit.name'),
        description: t('menuCard.desserts.egyptianCactusFruit.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/kaktusfrukt.png`,
        prices: { One: '20kr', two: '35kr', four: '45' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.desserts.zalabya.name'),
        description: t('menuCard.desserts.zalabya.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/zalabya.png`,
        prices: { mini: '30kr', small: '55kr', mid: '80kr', large: '105kr', extraLarge: '130kr' },
        dietTag: ''
      },
      {
        name: t('menuCard.desserts.konafaBelKeshta.name'),
        description: t('menuCard.desserts.konafaBelKeshta.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/konafa-bel-keshta.png`,
        prices: { mini: '25kr', small: '50kr', mid: '95kr', large: '110kr', extraLarge: '135kr' },
        dietTag: ''
      }
    ],     
    AppetizersAndSides: [
      {
        name: t('menuCard.appetizersAndSides.bazinjanPickled.name'),
        description: t('menuCard.appetizersAndSides.bazinjanPickled.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/bazinjan-pickled.png`,
        prices: { small: '20kr', medium: '35kr', large: '50kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.salad.name'),
        description: t('menuCard.appetizersAndSides.salad.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/salad.png`,
        prices: { small: '15kr', medium: '25kr', large: '40kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.tahini.name'),
        description: t('menuCard.appetizersAndSides.tahini.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/tahini.png`,
        prices: { small: '10kr', medium: '20kr', large: '30kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.babaGanoush.name'),
        description: t('menuCard.appetizersAndSides.babaGanoush.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/baba-ganoush.png`,
        prices: { small: '20kr', medium: '35kr', large: '50kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.friedPepper.name'),
        description: t('menuCard.appetizersAndSides.friedPepper.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fried-pepper.png`,
        prices: { small: '15kr', medium: '25kr', large: '40kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.friedAubergine.name'),
        description: t('menuCard.appetizersAndSides.friedAubergine.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fried-aubergine.png`,
        prices: { small: '20kr', medium: '35kr', large: '50kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.friedHotPepper.name'),
        description: t('menuCard.appetizersAndSides.friedHotPepper.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fried-hot-pepper.png`,
        prices: { small: '15kr', medium: '25kr', large: '40kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.friedPotatoLargeCuts.name'),
        description: t('menuCard.appetizersAndSides.friedPotatoLargeCuts.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/fried-potato.png`,
        prices: { small: '25kr', medium: '40kr', large: '60kr' },
        dietTag: getDietTags('vegan')
      },
      {
        name: t('menuCard.appetizersAndSides.mixedFries.name'),
        description: t('menuCard.appetizersAndSides.mixedFries.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/mixed-fries.png`,
        prices: { small: '30kr', medium: '45kr', large: '60kr' },
        dietTag: getDietTags('vegan')
      }
    ]
    ,
    Beverages: [
      {
        name: t('menuCard.beverages.sodas.name'),
        description: t('menuCard.beverages.sodas.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/brus.png`,
        prices: { small: '25kr',  large: '40kr' },
        dietTag: ''
      },
      {
        name: t('menuCard.beverages.karkade.name'),
        description: t('menuCard.beverages.karkade.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/karkade.png`,
        prices: { mini: '20kr', mid: '35kr',  large: '40kr' },
        dietTag:  getDietTags('vegan')
      },
      {
        name: t('menuCard.beverages.blackTea.name'),
        description: t('menuCard.beverages.blackTea.description'),
        imageUrl: `${process.env.PUBLIC_URL}/assets/img/menu/svart-te.png`,
        prices: { mini: '10kr', mid: '25kr',  large: '30kr' },
        dietTag:  getDietTags('vegan')
      }
    ]
    
      };
      const groupNames = {
        Breads: t('menuCard.breads.Bread'),
        mainCourses:t('menuCard.mainCourses.Name'),
        Desserts: t('menuCard.desserts.name'),
        AppetizersAndSides: t('menuCard.appetizersAndSides.name'),
        Beverages: t('menuCard.beverages.name')
      };
    

    return (
        <div>
                    {Object.keys(groupedMenuItems).map((group, groupIndex) => (
            <div key={groupIndex} className="menu-group">
                <h2 className="group-name">{groupNames[group]}</h2>
                <div className="menu-card">
                {groupedMenuItems[group].map((item, itemIndex) => (
                            <ItemComponent key={itemIndex} item={item} /> // Render the ItemComponent for each item
                        ))}
            <div className="rotating-truck-frame">
                <img src={process.env.PUBLIC_URL + 'assets/img/logos/truck.png'} 
                alt="Rotating Truck" 
                className="rotating-truck-menu"/>
            </div>
        </div>
            </div>
        ))}
        </div>

        
    );
}
