import logo from './logo.svg';
import './App.css';
import Header from './Components/Header';
import Main from './Components/Main'
import { useSyncLanguageAcrossTabs } from './Components/hooks/useSyncLanguageAcrossTabs';

function App() {
  useSyncLanguageAcrossTabs(); // Call the hook
  return (
    <div>
    <Header></Header>
    <Main></Main>
    </div>
  );
}

export default App;
