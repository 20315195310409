import React from 'react'
import MenuCard from './MenuCard'
import { useTranslation } from 'react-i18next';
export default function AboutUs() {
  const { t } = useTranslation();
    return (
        <div>
            <section id="about" className="about">
                <div className="container" data-aos="fade-up">
                <div className="section-title">
                        <h2>{t('nav_bar.menu')}</h2>
                    </div>
                    <div className="menu-content">
  <div className="central-image-container">
    <div className="central-image-wrapper" style={{ display: 'flex', justifyContent: 'center' }}>
      <img src={process.env.PUBLIC_URL + 'assets/img/logos/logo3.png'}
       alt="Central" 
       style={{ transform: 'scale(1.5)'}} className="central-image"/>
    </div>
    <div className="rotating-truck-container">
      <img src={process.env.PUBLIC_URL + 'assets/img/logos/truck.png'} alt="Food Truck" className="rotating-truck"/>
    </div>
  </div>
                    </div>
                        <MenuCard/>
                    </div>
            </section>
        </div>
    )
}
