import React from 'react'

import { useTranslation } from 'react-i18next';

export default function Services() {
    
    const { t } = useTranslation();
    return (
        <div>
            <section id="services" className="services section-bg">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{t('nav_bar.services')}</h2>                       
                    </div>
                </div>
            </section>
        </div>
    )
}
