import React, { useEffect } from 'react';
import Hero from './Hero';
import NavWrap from './NavWrap';
import { useTranslation } from 'react-i18next';

const Header = () => {
    const { t } = useTranslation();

    const select = (el, all = false) => {
        el = el.trim();
        return all ? [...document.querySelectorAll(el)] : document.querySelector(el);
    };

    const infiniteScroll = () => {
        let selectHeader = select('#header');
        if (window.scrollY > 100) {
            selectHeader.classList.add('header-scrolled');
        } else {
            selectHeader.classList.remove('header-scrolled');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', infiniteScroll);

        // Cleanup function to remove the event listener
        return () => {
            window.removeEventListener('scroll', infiniteScroll);
        };
    }, []); // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <header id="header" className="fixed-top">
                <div className="container d-flex align-items-center">
                    <h1 className="logo me-auto">
                        <a href="index.html" className="logo me-auto">
                            <img src={process.env.PUBLIC_URL + 'assets/img/logos/logo1.png'}
                                alt="Logo"
                                style={{ transform: "scale(2.5)", marginRight: "20px" }}
                                className="img-fluid" /> 
                                {t('headerTitle')}                            
                        </a>
                    </h1>
                    {/* Uncomment below if you prefer to use an image logo */}
                    {/* <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
                    <NavWrap></NavWrap>
                </div>
            </header>
            <Hero></Hero>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </div>
    );
};

export default Header;
