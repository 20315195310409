import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const useSyncLanguageAcrossTabs = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const syncLanguageChange = (e) => {
      if (e.key === 'language') {
        i18n.changeLanguage(e.newValue);
      }
    };

    const checkLanguageOnVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        const currentLang = localStorage.getItem('language');
        if (i18n.language !== currentLang) {
          i18n.changeLanguage(currentLang);
        }
      }
    };

    window.addEventListener('storage', syncLanguageChange);
    document.addEventListener('visibilitychange', checkLanguageOnVisibilityChange);

    // Cleanup
    return () => {
      window.removeEventListener('storage', syncLanguageChange);
      document.removeEventListener('visibilitychange', checkLanguageOnVisibilityChange);
    };
  }, [i18n]);
};
