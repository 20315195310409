import React from 'react'


import { useTranslation } from 'react-i18next';
export default function Career() {
    
    const { t } = useTranslation();
    return (
        <div>

            <section id="career" className="about">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>{t('nav_bar.career')}</h2>
  
                    </div>
                </div>

            </section>

        </div>
    )
}
